import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import MaskedInput from 'react-text-mask';
import { Button, Checkbox, Dimmer, Form, Grid, Loader, Modal } from 'semantic-ui-react';
import PromptNavigation from '../../../shared/component/prompt_navigation_component';
import { set_focus_to_app_header } from '../../../shared/tab_navigation_utility';
import { get_data, get_search_data, update_data } from '../action/constants_action';
import * as constants from '../constants';
import * as global_constants from './../../../global_constants';
import { add_primary_insurance, advanced_search_class, advanced_search_payer, get_contracts, get_insurance_types } from './../../../shared/action/shared_action';
import AdvancedControl from './../../../shared/component/advanced_control';
import AutoSearchComponent from './../../../shared/component/auto_search_component';
import ContractsSelectionBoxComponent from './../../../shared/component/contracts_selection_box_component';
import DatePicker from './../../../shared/component/date_picker_component';
import SelectionComponent from "./../../../shared/component/selection_component";
import { custom_date_format, unmask, zip_code_formation, get_lauch_darkley_key_value as get_launch_darkly_key_value, date_format_with_no_time } from './../../../shared/utility';
import * as session_storage from '../../../shared/session_storage_utility';
import * as local_storage from '../../../shared/local_storage_utility';
import { render_do_not_accept_assignment } from '../constants';
import { requestPatientStatements } from '../../../billing/billing_constants';
import { leviathan_bill_3775_insurance_incomplete_constant_save_validation } from '../../../feature_flip_constant';

class InsurancesAddEditComponent extends React.Component<any, any> {
    name_input: HTMLInputElement;
    _is_mount = false;
    constructor(props) {
        super(props);

        this.state = {
            is_loading: false,
            form: {
                insurance_name: '',
                insurance_code: '',
                address1: '',
                address2: '',
                city: '',
                country_id: 1,
                state: '',
                zip: '',
                balance_type: '',
                insurance_class: null,
                insurance_payer: null,
                insurance_type_id: '',
                tax_identification_number: '',
                taxonomy_code: '',
                type_of_bill: '',
                apply_specialty_modifiers: false,
                injury_date_required: false,
                payer_description: '',
                contact_name: '',
                contact_email: '',
                phone_customer_service: '',
                phone_claim_status: '',
                fax: '',
                weblink: '',
                weblink_user_id: '',
                weblink_password: '',
                dde: '',
                dde_user_id: '',
                dde_password: '',
                note: '',
                sec_routing: '',
                new_plan_required: false,
                icd_code_type: "1",
                icd10_start_date: this.default_date('10/01/2015', "MM/DD/YYYY"),
                authorization_required: false,
                is_active: true,
                selected_contracts: [],
                is_required: true,
                postal_code: '',
                province: '',
                do_not_accept_assignment:false
            },
            country_ids: [],
            balance_type: [],
            states: [],
            countries: [],
            icd_code_types: [],
            available_contracts: [],
            icd10_start_date: this.default_date('10/01/2015', "MM/DD/YYYY"),
            insurance_type_id: [],
            //master_data: null,
            error: {
                insurance_name: false,
                address1: false,
                city: false,
                country_id: false,
                state: false,
                balance_type: false,
                insurance_type_id: false,
                tax_identification_number: false,
                taxonomy_code: false,
                type_of_bill: false,
                payer_key_id: false,
                phone_customer_service: false,
                zip: false,
                insurance_class_id: false,
                contact_email: false,
                phone_claim_status: false,
                fax: false,
                province: false,
                postal_code: false,
                icd_10_date: false
            },
            selected_insurance_class: null,
            selected_payer: null,
            is_submitted: false,
            grid_conf: null,
            insurance_class_grid_data: null,
            insurance_payer_grid_data: null,
            by_pass_prompt: false,
            show_incomplete_insurance_modal: false
        };
    }
    page_name: string = 'insurance';
    page_metadata: any;
    is_grid_visible = false;
    loading = false;
    token = '';
    initial_state_form = null;
    initial_error_data = null;
    previous_error = false;
    insurance_type_id = [];
    selected_contracts_arr = [];
    class_search_enum = 'class';
    payer_search_enum = 'payer';
    go_next: boolean = false;
    is_cancel: boolean = false;
    menu_permission_object: Array<any> = [{ update: true }];
    disable_fields = false;
    //Get default date selection
    default_date = (date, pattern) => {
        if (date) {
            const dt = moment(date).format(pattern);
            return moment(dt);
        }
        return null;
    }
    indexes_to_remove_arr = [];
    user_type_r3 = 0;
    is_bms_or_sysadmin = false;
    code_id = 0;
    by_pass_prompt: boolean = false;
    form_is_valid: boolean = false;
    ff_allow_user_save_incomplete_insurance: boolean = false;
    error_in_insurance_class: boolean = false;
    error_in_insurance_type: boolean = false;

    UNSAFE_componentWillMount = () => {
        //this.setState({
        //    master_data: this.props.user_login_details.master_data.data,
        //});
        this.page_metadata = constants.insurance;
        this.initial_state_form = { ...this.state.form };
        this.initial_error_data = this.state.error;
        this.token = this.props.user_login_details.user_data.data.token_details.access_token;
        this.code_id = this.props.match.params && this.props.match.params.id ? this.props.match.params.id : 0;
        this.is_grid_visible = this.props.history.location.state && this.props.history.location.state.is_grid_visible ? this.props.history.location.state.is_grid_visible : false;
        this.user_type_r3 = this.props.user_login_details.user_data.data.user_type_r3_id;
        var old_user_data = local_storage.get("auth_data");
        var company_service_type = 0;
        this.ff_allow_user_save_incomplete_insurance = this.props.leviathanBill3775InsuranceIncompleteConstantSaveValidation;
        if (old_user_data) {
            company_service_type = old_user_data.company_service_type;
        }
        //allow same feature for customer or partner user if they are software only as  sys admin and bms
        this.is_bms_or_sysadmin = (this.user_type_r3 === 1 || this.user_type_r3 === 2) ? true : (company_service_type == global_constants.CompanyServiceType.SoftwareOnly?true: false);
        if (this.code_id > 0) {
            this.checkUpdatePermission();
            this.get_insurance_by_id(this.code_id);
        }
    }
    get_insurance_by_id = code_id => {
        this.setState({
            is_loading: true
        })
        const form = { ...this.state.form }
        let { selected_insurance_class, selected_payer } = this.state
        let url = this.page_metadata.api.get.url;
        url = url.replace('id_value', code_id);
        get_data(this.token, url).then(res => {
            if (res.data && res.data.data) {
                let result = res.data.data;
                let selected_contract = result.available_contract.sort((a, b) => a.name.localeCompare(b.name))
                selected_contract = selected_contract.map(item => { item.is_active = false; return item });
                form.insurance_name = result.name ? result.name : '';
                form.insurance_code = result.insurance_code ? result.insurance_code : '';
                form.address1 = result.address.address1 ? result.address.address1 : '';
                form.address2 = result.address.address2;
                form.city = result.address.city ? result.address.city : '';
                form.country_id = result.address.country_id ? result.address.country_id : 1;
                form.state = result.address.state_id ? result.address.state_id : '';
                form.zip = result.address.zip ? result.address.zip : '';
                form.balance_type = result.balance_type ? result.balance_type : '';
                form.insurance_class = result.insurance_class ? {
                    code: result.insurance_class.insurance_class_code,
                    id: result.insurance_class.id,
                    title: result.insurance_class.name,
                    name: result.insurance_class.name,
                    label: result.insurance_class.insurance_class_code + (result.insurance_class.name ? `- ${result.insurance_class.name}` : '')
                } : null
                form.insurance_payer = result.payer_key_id ? {
                    code: result.payer_name,
                    description: result.payer_desc,
                    id: result.payer_key_id,
                    name: result.payer_name,
                    label: (result.payer_name) + " - " + (result.payer_desc),
                    title: result.payer_name
                } : null
                form.insurance_type_id = result.insurance_type_id;
                form.tax_identification_number = result.tax_identification_number || '';
                form.taxonomy_code = result.taxonomy_code || '';
                form.type_of_bill = result.type_of_bill || '';
                form.injury_date_required = result.injury_date_required_flag ? result.injury_date_required_flag : false;
                form.payer_description = result.payer_desc;
                form.contact_name = result.contact_name ? result.contact_name : '';
                form.contact_email = result.contact_email ? result.contact_email : '';
                form.phone_customer_service = result.phone_cust ? result.phone_cust : '';
                form.phone_claim_status = result.phone_clm_status ? result.phone_clm_status : '';
                form.fax = result.fax ? result.fax : '';
                form.weblink = result.web_link ? result.web_link : '';
                form.weblink_user_id = result.web_link_user_id;
                form.weblink_password = result.web_link_password;
                form.dde = result.dde;
                form.dde_user_id = result.dde_user_id;
                form.dde_password = result.dde_pwd;
                form.note = result.note;
                form.sec_routing = result.secure_routing;
                form.new_plan_required = result.dx_change_poc_required ? result.dx_change_poc_required : false;
                form.icd_code_type = result.icd_code_for_billing !== null ? result.icd_code_for_billing : 1;
                form.icd10_start_date = result.icd10_start_date ? this.default_date(result.icd10_start_date, "MM/DD/YYYY") : result.icd10_start_date;
                form.authorization_required = result.is_authorization_required ? result.is_authorization_required : false;
                form.apply_specialty_modifiers = result.apply_specialty_modifiers ? result.apply_specialty_modifiers : false;
                form.is_active = result.is_active;
                form.selected_contracts = selected_contract;
                form.is_required = result.address.is_required ? result.address.is_required : true;
                form.postal_code = result.address.postal_code ? result.address.postal_code : '';
                form.province = result.address.province ? result.address.province : ''
                form.do_not_accept_assignment = result.do_not_accept_assignment?result.do_not_accept_assignment:false;
                // for default selected item for payer and insurance class
                selected_insurance_class = form.insurance_class
                selected_payer = form.insurance_payer
                this.initial_state_form = { ...form }
                this.setState({
                    form,
                    selected_insurance_class,
                    selected_payer,
                    is_loading: false
                })
                
            } else {
                toastr.warning('', "No record found");
                this.props.history.push("/admin/insurance/search");
            }
        }, error => {
            this.setState({
                is_loading: false
            })
            if (error.response.data)
                toastr.error('', error.response.data.messages[0].message);
        })
    }
    componentDidMount = async () => {
        this._is_mount = true;
        document.body.classList.add('admin-framework');
        let elem = document.getElementById('search-criteria-container');
        let all_form_elem = elem.getElementsByTagName('input');
        all_form_elem[0].focus();
        this.token = this.props.user_login_details.user_data.data.token_details.access_token; // update token at component update       

        const master_data = { ...this.props.user_login_details.master_data.data }

        // Following lines will fetch the states from master data
        const states = master_data.states.map((val, index) => { return { key: index, value: val.id, text: val.name } });

        // Following lines will fetch the countries from master data
        const countries = master_data.country.map((val, index) => { return { key: index, value: val.id, text: val.name } });

        // Following lines will fetch the countries from master data
        const icd_code_types = master_data.icd_code_type.map((val, index) => { return { key: index, value: val.id, text: val.name } });

        // Following lines will fetch the balance types from master data
        const balance_type = master_data.balance_type.map((val, index) => { return { key: index, value: val.id, text: val.name } });

        var default_insurance_types = [{ key: '00', value: '', text: 'List' }];

        const insurance_types = await get_insurance_types(this.token).then(res => {
            if (res.data.data != null && res.data.data.length > 0) {
                //this.insurance_type_id = res.data.data;
                return res.data.data.map((val, index) => {
                    return { key: index, value: val.id, text: val.name }
                });
            }
        }); // Get All insurance types from api

        let available_contracts = await get_contracts(this.token).then(res => {
            if (res.data.data != null && res.data.data.length > 0) {
                return res.data.data;
            }
        });
        if (this.state.form.selected_contracts.length > 0) {
            let select_contracts = this.state.form.selected_contracts.map(item => item.id)
            available_contracts = available_contracts.filter(item => !select_contracts.includes(item.id))
            // available_contracts = available_contracts.sort((a, b) => a.name.localeCompare(b.name))
        }

        //const available_contracts = this.props.insurance_contract; // Get All contracts from api

        if (this._is_mount) {
            this.setState({ available_contracts, balance_type, insurance_type_id: insurance_types, icd_code_types, states, country_ids: countries });
        }

        // Following line will create a clone of form state that would be treated as a initial state
    }

    componentWillUnmount = () => {
        this._is_mount = false;
        document.body.classList.remove('admin-framework');
        if (this.props.history.location) {
            var route_array = this.props.history.location.pathname.split('/');
            route_array && route_array[2] !== 'insurance' && session_storage.remove('insurance_search_criteria');
        }
    }

    componentDidUpdate(previousProps, previousState) {
        this.token = this.props.user_login_details.user_data.data.token_details.access_token; // update token at component update

    }

    checkUpdatePermission = () => {
        this.menu_permission_object = this.props.shared_details.left_menu_data.data
            .filter((menu) => {
                return menu.name == 'Edit Insurances';
            });
        
        if (this.menu_permission_object.length == 0 || !this.menu_permission_object[0].view) {
            this.disable_fields = true;
        }
    }

    // check if data has only letters and numbers
    input_data_validation = (data, type) => {
        if (type === 'number') {
            data = data.toString().replace(/[^0-9]/g, '');
            return (data.length != 10);
        } else if (type === 'country_id' || type == 'insurance_type_id') {
            let reg = new RegExp('/^[\s\d\s]*$/');
            return typeof data !== 'number' && !data.match(reg);
        } else if (type === 'zip') {
            return !(data.length == 5 || data.length == 9);
        } else if (type === 'email') {
            let email_invalid = data.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? false : true;
            return (email_invalid);
        } else if (type === 'postal_code') {
            data = data.toString().replace(/[^a-z0-9]/g, '');
            return (data === '');
        } else if (type === 'tax_identification_number') {
            data = data.toString().replace(/[^0-9]/g, '');
            return (data ? data.length > 0 && data.length != 9 : false);
        } else if (type === 'taxonomy_code') {
            return (data ? data.length > 0 && data.length != 10 : false);
        } else if (type === 'type_of_bill') {
            data = data.toString().replace(/[^0-9]/g, '');
            return (data ? data.length > 0 && data.length != 3 : false);
        }
    }


    select_contracts_to_add = (e, item, idx) => {
        var data = Object.assign([], this.state.available_contracts);
        if (item.checked) {
            data[idx].is_active = true;
        } else {
            data[idx].is_active = false;
        }
        if (this._is_mount) {
            this.setState({ available_contracts: data });
        }
    }

    select_contracts_to_remove = (e, item, idx) => {
        var data = Object.assign([], this.state.form.selected_contracts);
        if (item.checked) {
            data[idx].is_active = true;
        } else {
            data[idx].is_active = false;
        }
        if (this._is_mount) {
            this.setState({ form: { ...this.state.form, selected_contracts: data } });
        }
    }

    add_selected_contracts = () => {
        const available_contracts = [...this.state.available_contracts];

        var remove_contracts = available_contracts.filter((el, index) => {
            return el.is_active == false
        });
        var selected_contracts = available_contracts.filter((el, index) => {
            let res = el.is_active
            el.is_active = false
            return res == true
        });
        if (this._is_mount) {
            this.setState({ form: { ...this.state.form, selected_contracts: this.state.form.selected_contracts.concat(selected_contracts) }, available_contracts: remove_contracts });
        }
    }

    add_all_contracts = () => {
        const available_contracts = [...this.state.form.selected_contracts, ...this.state.available_contracts];
        const selected_contracts = available_contracts.filter((el, index) => {

            el.is_active = false
            return true
        });

        if (this._is_mount) {
            this.setState({ form: { ...this.state.form, selected_contracts: selected_contracts }, available_contracts: [] });
        }
    }

    remove_selected_contracts = () => {
        const available_contracts = [...this.state.form.selected_contracts];
        const remove_contracts = available_contracts.filter((el, index) => {
            return el.is_active == false
        });
        const selected_contracts = available_contracts.filter((el, index) => {
            let res = el.is_active
            el.is_active = false
            return res == true
        });

        if (this._is_mount) {
            this.setState({ form: { ...this.state.form, selected_contracts: remove_contracts }, available_contracts: [...selected_contracts, ...this.state.available_contracts] });
        }

    }

    remove_all_contracts = () => {
        const available_contracts = [...this.state.form.selected_contracts, ...this.state.available_contracts];
        const selected_contracts = available_contracts.filter((el, index) => {

            el.is_active = false
            return true
        });

        if (this._is_mount) {
            this.setState({ available_contracts: selected_contracts, form: { ...this.state.form, selected_contracts: [] } });
        }
    }

    // validate form before saving data
    validate_form = () => {
        const error = { ...this.state.error };
        const form = this.state.form;
        this.error_in_insurance_class = false;
        this.error_in_insurance_type = false;

        if (form.insurance_class == null || form.insurance_class.id == 0) {
            this.error_in_insurance_class = true;
        }
        error.insurance_name = (form.insurance_name.trim() == '') ? true : false;
        error.address1 = (form.address1.trim() == '') ? true : false;
        error.city = (form.city.trim() == '') ? true : false;
        error.country_id = this.input_data_validation(form.country_id, 'country_id');
        error.balance_type = (form.balance_type.trim() == '') ? true : false;
        error.insurance_type_id = this.input_data_validation(form.insurance_type_id, 'insurance_type_id');
        error.tax_identification_number = (form.tax_identification_number ? form.tax_identification_number.length > 0 && form.tax_identification_number.length != 9 : false);
        error.taxonomy_code = (form.taxonomy_code ? form.taxonomy_code.length > 0 && form.taxonomy_code.length != 10 : false);
        error.type_of_bill = (form.type_of_bill ? form.type_of_bill.length > 0 && form.type_of_bill.length != 3 : false);
        error.payer_key_id = this.is_bms_or_sysadmin ? form.insurance_payer ? (form.insurance_payer.id == '' ? true : false) : true : false;
        error.phone_customer_service = this.input_data_validation(form.phone_customer_service, 'number');
        error.insurance_class_id = this.error_in_insurance_class;
        error.contact_email = form.contact_email.length > 0 && this.input_data_validation(form.contact_email, 'email');
        error.phone_claim_status = form.phone_claim_status.length > 0 && this.input_data_validation(form.phone_claim_status, 'number');
        error.fax = form.fax.length > 0 && this.input_data_validation(form.fax, 'number');

        if (this.state.form.country_id != 1) {
            error.province = (form.province.trim() == '') ? true : false;
            error.postal_code = this.input_data_validation(form.postal_code, 'postal_code');

        } else {
            error.state = (form.state.trim() == '') ? true : false;
            error.zip = this.input_data_validation(form.zip, 'zip');

        }
        error.icd_10_date = form.icd_code_type == '1' ? form.icd10_start_date ? false : true : false
        if (this._is_mount) {
            this.setState({
                error: error,
                is_submitted: true
            });
        }
        if (Object.keys(error).some((data) => { return error[data] == true })) {
            this.previous_error = true;
            this.form_is_valid = false;
            if (this.ff_allow_user_save_incomplete_insurance) {
                if (error.insurance_type_id) {
                    this.error_in_insurance_type = true;
                }
                return;
            }
            else {
                toastr.error('', global_constants.constants.mandatory_fields);
                return;
            }
        }
        this.form_is_valid = true;

    }
    // save form data
    save_handle = async (e, from_pop_up) => {
        e.preventDefault();
        this.go_next = false;
        this.validate_form();
        if (this.form_is_valid) {
            this.setState({
                is_loading: true
            })
            let form_data = { ...this.state.form };
            /*  Here we need to create the data that is needed to be sent to the API
             * 
             * */
            let new_insurance_data: any = {};

            new_insurance_data.available_contract = form_data.selected_contracts;
            new_insurance_data.insurance_code = form_data.insurance_code ? form_data.insurance_code : '';
            new_insurance_data.address = {
                address1: form_data.address1,
                address2: form_data.address2,
                city: form_data.city,
                province: form_data.province,
                country_id: form_data.country_id,
                is_required: form_data.is_required,
                zip: form_data.zip,
                state_id: form_data.state,
                postal_code: form_data.postal_code,
            };
            new_insurance_data.do_not_accept_assignment = form_data.do_not_accept_assignment,
                new_insurance_data.injury_date_required_flag = form_data.injury_date_required;
            new_insurance_data.contact_name = form_data.contact_name ? form_data.contact_name.trim() : '';
            new_insurance_data.contact_email = form_data.contact_email ? form_data.contact_email.trim() : '';
            new_insurance_data.phone_clm_status = form_data.phone_claim_status ? form_data.phone_claim_status.replace(/\D/g, '') : ''
            new_insurance_data.fax = form_data.fax ? form_data.fax.replace(/\D/g, '') : '';
            new_insurance_data.web_link = form_data.weblink ? form_data.weblink.trim() : '';
            new_insurance_data.dx_change_poc_required = form_data.new_plan_required;
            new_insurance_data.is_active = form_data.is_active;
            new_insurance_data.insurance_class = form_data.insurance_class ? {
                id: form_data.insurance_class.id,
                name: form_data.insurance_class.name,
                Insurance_class_code: form_data.insurance_class.code
            } : {};
            new_insurance_data.insurance_type = form_data.insurance_class ? form_data.insurance_class.name : '';
            new_insurance_data.id = this.code_id ? this.code_id : 0;
            new_insurance_data.name = form_data.insurance_name;
            new_insurance_data.insurance_class_id = form_data.insurance_class ? form_data.insurance_class.id : 0;
            new_insurance_data.phone_cust = form_data.phone_customer_service;
            new_insurance_data.is_authorization_required = form_data.authorization_required;
            new_insurance_data.apply_specialty_modifiers = form_data.apply_specialty_modifiers;
            new_insurance_data.insurance_type_id = form_data.insurance_type_id;
            new_insurance_data.tax_identification_number = form_data.tax_identification_number;
            new_insurance_data.taxonomy_code = form_data.taxonomy_code;
            new_insurance_data.type_of_bill = form_data.type_of_bill;
            new_insurance_data.balance_type = form_data.balance_type;

            if (this.is_bms_or_sysadmin) {
                new_insurance_data.payer_id = form_data.insurance_payer.id;
                new_insurance_data.payer_key_id = form_data.insurance_payer.id;
                new_insurance_data.dde = form_data.dde ? form_data.dde.trim() : '';
                new_insurance_data.dde_user_id = form_data.dde_user_id;
                new_insurance_data.dde_pwd = form_data.dde_password ? form_data.dde_password.trim() : '';
                new_insurance_data.web_link_user_id = form_data.weblink_user_id ? form_data.weblink_user_id.trim() : '';
                new_insurance_data.web_link_password = form_data.weblink_password ? form_data.weblink_password.trim() : '';
                new_insurance_data.note = form_data.note ? form_data.note.trim() : '';
                new_insurance_data.secure_routing = form_data.sec_routing;
                new_insurance_data.icd_code_for_billing = form_data.icd_code_type;
                new_insurance_data.icd10_start_date = form_data.icd10_start_date;
            }

            new_insurance_data.payer_id = form_data.insurance_payer.id && form_data.insurance_payer.id != null
                ? form_data.insurance_payer.id.toString()
                : null;
            new_insurance_data.icd10_start_date = new_insurance_data.icd10_start_date && new_insurance_data.icd10_start_date != null
                ? date_format_with_no_time(form_data.icd10_start_date)
                : null;

            session_storage.set('insurance_search_criteria', {});
            if (this.code_id) {
                let url = this.page_metadata.api.update.url;
                url = url.replace('id_value', this.code_id);
                update_data(this.token, url, new_insurance_data).then(res => {
                    if (res.data && res.data.data) {
                        this.go_next = true;
                        toastr.success('', constants.insurance.messages.update);
                        this.initial_state_form = { ...form_data }
                        this.setState({
                            is_loading: false,
                            by_pass_prompt: !from_pop_up ? true : false
                        })
                        if (!from_pop_up && this.go_next) {
                            this.go_next = false;
                            let last_report_navigation = local_storage.get('last_report_navigation');
                            local_storage.remove('last_report_navigation');
                            if (!last_report_navigation) {
                                this.props.history.goBack()
                            } else {
                                this.props.history.push(last_report_navigation);
                            }
                        }
                    } else {
                        if (res.data.messages[0].message.indexOf('<br') > -1) {
                            this.show_html_content_toaster(res.data.messages[0].message);
                        } else {
                            toastr.error('', res.data.messages[0].message);
                        }

                    }
                }, error => {
                    if (this._is_mount) {
                        this.go_next = false;
                        this.setState({
                            is_loading: false
                        });
                    }
                    if (error.response.data) toastr.error('', error.response.data.messages[0].message);
                })
            } else {
                await add_primary_insurance(this.token, new_insurance_data).then(res => {
                    if (res.data && res.data.data) {
                        this.go_next = true;
                        toastr.success('', constants.insurance.messages.save);
                        this.initial_state_form = { ...form_data }
                        this.setState({
                            is_loading: false,
                            by_pass_prompt: !from_pop_up ? true : false
                        });
                        if (!from_pop_up && this.go_next) {
                            this.go_next = false;
                            let last_report_navigation = local_storage.get('last_report_navigation');
                            local_storage.remove('last_report_navigation');
                            if (!last_report_navigation) {
                                this.props.history.goBack()
                            } else {
                                this.props.history.push(last_report_navigation);
                            }
                        }
                    } else {
                        if (res.data.messages[0].message.indexOf('<br') > -1) {
                            this.show_html_content_toaster(res.data.messages[0].message);
                        } else {
                            toastr.error('', res.data.messages[0].message);
                        }
                        this.go_next = false;
                        if (this._is_mount) {
                            this.setState({
                                is_loading: false
                            });
                        }
                    }
                }, error => {
                    if (this._is_mount) {
                        this.go_next = false;
                        this.setState({
                            is_loading: false
                        });
                    }
                    if (error.response.data) toastr.error('', error.response.data.messages[0].message);
                });
            }
        }
        else if (!this.form_is_valid && this.ff_allow_user_save_incomplete_insurance) {
            if (this.error_in_insurance_class || this.error_in_insurance_type) {
                toastr.error('', 'Insurance Class and Insurance Type ID are required to save');
                return;
            }
            else {
                //show modal
                if (this._is_mount) {
                    this.setState({ show_incomplete_insurance_modal: true });
                }
            }
        }
    }

    update_insurance_data = (new_insurance_data: any, form_data:any, from_pop_up:boolean) => {
        let url = this.page_metadata.api.update.url;
        url = url.replace('id_value', this.code_id);
        update_data(this.token, url, new_insurance_data).then(res => {
            if (res.data && res.data.data) {
                this.go_next = true;
                toastr.success('', constants.insurance.messages.update);
                this.initial_state_form = { ...form_data }
                this.setState({
                    is_loading: false,
                    by_pass_prompt: !from_pop_up ? true : false
                })
                if (!from_pop_up && this.go_next) {
                    this.go_next = false;
                    let last_report_navigation = local_storage.get('last_report_navigation');
                    local_storage.remove('last_report_navigation');
                    if (!last_report_navigation) {
                        this.props.history.goBack()
                    } else {
                        this.props.history.push(last_report_navigation);
                    }
                }
            } else {
                if (res.data.messages[0].message.indexOf('<br') > -1) {
                    this.show_html_content_toaster(res.data.messages[0].message);
                } else {
                    toastr.error('', res.data.messages[0].message);
                }

            }
        }, error => {
            if (this._is_mount) {
                this.go_next = false;
                this.setState({
                    is_loading: false
                });
            }
            if (error.response.data) toastr.error('', error.response.data.messages[0].message);
        })
    }

    create_new_insurance_data_object: any = () => {
        this.setState({
            is_loading: true
        })
        let form_data = { ...this.state.form };
        /*  Here we need to create the data that is needed to be sent to the API
         * 
         * */
        let new_insurance_data: any = {};

        new_insurance_data.available_contract = form_data.selected_contracts;
        new_insurance_data.insurance_code = form_data.insurance_code ? form_data.insurance_code : '';
        new_insurance_data.address = {
            address1: form_data.address1,
            address2: form_data.address2,
            city: form_data.city,
            province: form_data.province,
            country_id: form_data.country_id,
            is_required: form_data.is_required,
            zip: form_data.zip,
            state_id: form_data.state,
            postal_code: form_data.postal_code,
        };
        new_insurance_data.do_not_accept_assignment = form_data.do_not_accept_assignment,
            new_insurance_data.injury_date_required_flag = form_data.injury_date_required;
        new_insurance_data.contact_name = form_data.contact_name ? form_data.contact_name.trim() : '';
        new_insurance_data.contact_email = form_data.contact_email ? form_data.contact_email.trim() : '';
        new_insurance_data.phone_clm_status = form_data.phone_claim_status ? form_data.phone_claim_status.replace(/\D/g, '') : ''
        new_insurance_data.fax = form_data.fax ? form_data.fax.replace(/\D/g, '') : '';
        new_insurance_data.web_link = form_data.weblink ? form_data.weblink.trim() : '';
        new_insurance_data.dx_change_poc_required = form_data.new_plan_required;
        new_insurance_data.is_active = form_data.is_active;
        new_insurance_data.insurance_class = form_data.insurance_class ? {
            id: form_data.insurance_class.id,
            name: form_data.insurance_class.name,
            Insurance_class_code: form_data.insurance_class.code
        } : {};
        new_insurance_data.insurance_type = form_data.insurance_class ? form_data.insurance_class.name : '';
        new_insurance_data.id = this.code_id ? this.code_id : 0;
        new_insurance_data.name = form_data.insurance_name;
        new_insurance_data.insurance_class_id = form_data.insurance_class ? form_data.insurance_class.id : 0;
        new_insurance_data.phone_cust = form_data.phone_customer_service;
        new_insurance_data.is_authorization_required = form_data.authorization_required;
        new_insurance_data.apply_specialty_modifiers = form_data.apply_specialty_modifiers;
        new_insurance_data.insurance_type_id = form_data.insurance_type_id;
        new_insurance_data.tax_identification_number = form_data.tax_identification_number;
        new_insurance_data.taxonomy_code = form_data.taxonomy_code;
        new_insurance_data.type_of_bill = form_data.type_of_bill;
        new_insurance_data.balance_type = form_data.balance_type;

        if (this.is_bms_or_sysadmin) {
            new_insurance_data.payer_id = form_data.insurance_payer != null ? form_data.insurance_payer.id  : null;
            new_insurance_data.payer_key_id = form_data.insurance_payer != null ? form_data.insurance_payer.id : null;
            new_insurance_data.dde = form_data.dde ? form_data.dde.trim() : '';
            new_insurance_data.dde_user_id = form_data.dde_user_id;
            new_insurance_data.dde_pwd = form_data.dde_password ? form_data.dde_password.trim() : '';
            new_insurance_data.web_link_user_id = form_data.weblink_user_id ? form_data.weblink_user_id.trim() : '';
            new_insurance_data.web_link_password = form_data.weblink_password ? form_data.weblink_password.trim() : '';
            new_insurance_data.note = form_data.note ? form_data.note.trim() : '';
            new_insurance_data.secure_routing = form_data.sec_routing;
            new_insurance_data.icd_code_for_billing = form_data.icd_code_type;
            new_insurance_data.icd10_start_date = form_data.icd10_start_date;
        }

        if (form_data.insurance_payer != null) {
            new_insurance_data.payer_id = form_data.insurance_payer.id.toString();
        }

        new_insurance_data.icd10_start_date = new_insurance_data.icd10_start_date && new_insurance_data.icd10_start_date != null
            ? date_format_with_no_time(form_data.icd10_start_date)
            : null;

        session_storage.set('insurance_search_criteria', {});
        return new_insurance_data;
    }

    render_insurance_profile_incomplete_modal() {
        return (
            <Modal
                onClose={this.close_modal}
                centered={false}
                className='default-modal'
                open={this.state.show_incomplete_insurance_modal}
                closeIcon
                closeOnDimmerClick={false}
                id='insurance_profile_incomplete_modal'
                closeOnDocumentClick={false}
            >
                <Modal.Header>Insurance Profile Incomplete</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <div className='common-forms' style={{ padding: '0' }}>
                            <p>Additional information is required to format claims.  Charges cannot be billed to this insurance until the required fields have been added.</p>
                                <p><strong>NOTE: </strong>Invalid address information will prevent the insurance from saving.
                                </p>
                            <Grid>
                                <Grid.Column computer={16} style={{ textAlign: 'right' }} className="mar-t-15 mar-b-15">
                                    <Button basic onClick={this.close_modal}>Cancel</Button>
                                    <Button
                                        id='save_user_acknowledged_incomplete_insurance_button'
                                        type='submit'
                                        onClick={() => {
                                            this.save_user_acknowledged_incomplete_insurance();
                                        }}
                                        primary>Save</Button>
                                </Grid.Column>
                            </Grid>
                        </div>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        );
    }

    close_modal = () => {
        if (this._is_mount) {
            this.setState({ show_incomplete_insurance_modal: false });
        }
    }

    save_user_acknowledged_incomplete_insurance = () => {
        this.close_modal();
        let new_insurance_data = this.create_new_insurance_data_object();
        if (this.code_id) {
            this.update_insurance_data(new_insurance_data, { ...this.state.form }, false);
        }
        else {
            add_primary_insurance(this.token, new_insurance_data).then(res => {
                if (res.data && res.data.data) {
                    this.go_next = true;
                    toastr.success('', constants.insurance.messages.save);
                    this.initial_state_form = { ...this.state.form }
                    this.setState({
                        is_loading: false,
                        by_pass_prompt: true
                    });
                    if (this.go_next) {
                        this.go_next = false;
                        let last_report_navigation = local_storage.get('last_report_navigation');
                        local_storage.remove('last_report_navigation');
                        if (!last_report_navigation) {
                            this.props.history.goBack()
                        } else {
                            this.props.history.push(last_report_navigation);
                        }
                    }
                } else {
                    if (res.data.messages[0].message.indexOf('<br') > -1) {
                        this.show_html_content_toaster(res.data.messages[0].message);
                    } else {
                        toastr.error('', res.data.messages[0].message);
                    }
                    this.go_next = false;
                    if (this._is_mount) {
                        this.setState({
                            is_loading: false
                        });
                    }
                }
            }, error => {
                if (this._is_mount) {
                    this.go_next = false;
                    this.setState({
                        is_loading: false
                    });
                }
                if (error.response.data) toastr.error('', error.response.data.messages[0].message);
            });
        }
        
    }

    // for cancel button

    cancel_handler = () => {
        this.by_pass_prompt = true;
        this.setState(
            {
                by_pass_prompt: true
            },
            () => {
                let last_report_navigation = local_storage.get('last_report_navigation');
                local_storage.remove('last_report_navigation');
                if (!last_report_navigation) {
                    this.props.history.goBack()
                } else {
                    this.props.history.push(last_report_navigation);
                }                
            }
        );
    };


    // set input field value in state
    handleChange = (e) => {

        const input_field = e.target.name;
        let input_value = e.target.value;
        const error = { ...this.state.error };

        /* Following lines we will check for validation while changing certain fields
         * 
         * */
        if ((input_field == 'insurance_name' || input_field == 'address1' || input_field == 'city' || input_field == 'balance_type') && this.state.is_submitted) {
            error[input_field] = (input_value.trim() == '') ? true : false;
        } else if (input_field == 'zip') {
            input_value = input_value && input_value.length > 0 && input_value.replace(/[^0-9]/g, '');
            if (this.state.is_submitted) {
                let zip_error = this.input_data_validation(input_value, 'zip');
                error[input_field] = zip_error;
            }
        } else if (input_field == 'postal_code') {
            input_value = (e.target.validity.valid) ? (Number(e.target.value).toString().length <= 20 ? e.target.value.replace(/[^a-z0-9]/gi, '') : this.state.form.postal_code) : this.state.form.postal_code;
            if (this.state.is_submitted) {
                error[input_field] = (input_value.trim() == '') ? true : false;
            }
        } else if (input_field == 'phone_customer_service' || input_field == 'phone_claim_status' || input_field == 'fax') {
            let error_val = this.input_data_validation(unmask(input_value), 'number');
            input_value = input_value && input_value.length > 0 && input_value.replace(/[^0-9]/g, '');
            let is_error = input_field == 'phone_customer_service' ? error_val : input_value.length > 0 && error_val
            error[input_field] = is_error;
        } else if (input_field == 'contact_email') {
            error[input_field] = this.input_data_validation(input_value, 'email');
        } else if (input_field == 'province') {
            input_value = e.target.value.replace(/[^a-z0-9]/gi, '')
            //input_value = (e.target.validity.valid) ? (Number(e.target.value) <= 2147483647 ? e.target.value : this.state.form.postal_code) : this.state.form.postal_code;
            if (this.state.is_submitted) {
                error[input_field] = (input_value.trim() == '') ? true : false;
            }
        } else if (input_field === 'tax_identification_number') {
            input_value = input_value && input_value.length > 0 && input_value.replace(/[^0-9]/g, '');
            if (this.state.is_submitted) {
                error[input_field] = this.input_data_validation(input_value, 'tax_identification_number');
            }
        } else if (input_field === 'taxonomy_code') {
            input_value = input_value && input_value.length > 0 && input_value.replace(/[^0-9a-zA-Z]/g, '');
            if (this.state.is_submitted) {
                error[input_field] = this.input_data_validation(input_value, 'taxonomy_code');
            }
        } else if (input_field === 'type_of_bill') {
            input_value = input_value && input_value.length > 0 && input_value.replace(/[^0-9]/g, '');
            if (this.state.is_submitted) {
                error[input_field] = this.input_data_validation(input_value, 'type_of_bill');
            }
        }

        if (this._is_mount) {
            this.setState({ form: { ...this.state.form, [input_field]: input_value }, error: error });
        }
    }

    handleDropDownChange = (e, item) => {
        const error = { ...this.state.error };
        var form = { ...this.state.form };
        let field = item.name;
        let value;

        if (field == 'country_id' || field == 'insurance_type_id') {
            value = parseInt(item.value);
        }
        else {
            value = item.value;
        }

        if (value != '') {
            error[field] = false;
        }



        if (field == 'country_id' && this.state.is_submitted) {
            if (value != 1) {
                error.state = false;
                error.zip = false;
                error.province = true;
                error.postal_code = true;
                form.state = "";
                form.zip = "";
            }
            if (value == 1) {
                error.state = true;
                error.zip = true;
                error.province = false;
                error.postal_code = false;
                form.province = '';
                form.postal_code = '';
            }
        }

        if (this._is_mount) {
            this.setState({ form: { ...form, [field]: value }, error: error });
        }
    }

    // show modal on state change
    controlHandler = (event) => {

        if (this._is_mount) {
            this.setState({ showModal: true });

        }
    }

    // get the quick search class apis data
    get_insurance_class_list = async (search_keyword) => {
        let url = constants.insurance.api.insurance_class_quick_search + "?keyword=" + search_keyword + "&page_size=" + global_constants.constants.Quick_Search_Suggestion_List_Size;
        let result = await get_search_data(this.token, url);
        result.data.data = result.data && result.data.data ? result.data.data.sort((a, b) => b.id - a.id) : result
        return result
    }
    // get the quick search provider apis data
    get_payer_list = async (search_keyword) => {
        let url = constants.insurance.api.payer_quick_search + "?keyword=" + search_keyword + "&page_size=" + global_constants.constants.Quick_Search_Suggestion_List_Size;
        let result = await get_search_data(this.token, url);
        result.data.data = result.data && result.data.data ? result.data.data.sort((a, b) => a.name.localeCompare(b.name)) : result
        return result
    }

    // on provider search item selection
    on_advanced_search_grid_row_election = (selectedRow, type) => {
        var selected_row = null;
        var state = { ...this.state };
        var form = { ...state.form };
        if (this._is_mount) {
            if (type == this.class_search_enum) {
                this.setState(prevState => ({
                    selected_insurance_class: selectedRow,
                    form: { ...prevState.form, insurance_class: selectedRow }
                }));
            } else {
                this.setState(prevState => ({
                    selected_payer: selectedRow,
                    form: {
                        ...prevState.form, insurance_payer: selectedRow
                    }
                }));
            }
        }

    }

    // clear data on clear icon
    clear_quick_search = (type) => {
        const { error } = this.state;
        if (type == this.payer_search_enum && this.state.is_submitted) {
            error.payer_key_id = true;
        }

        if (type == this.class_search_enum && this.state.is_submitted) {
            error.insurance_class_id = true;
        }
        if (this._is_mount) {
            if (type == this.class_search_enum) {
                this.setState({
                    selected_insurance_class: {},
                    form: { ...this.state.form, insurance_class: null },
                    error
                });
            } else {
                this.setState({
                    selected_payer: {},
                    form: { ...this.state.form, insurance_payer: null },
                    error
                });
            }
        }
    }

    // get the searched value which is typed for searching location or providers
    get_input_value = (input, type) => {

        const error = { ...this.state.error };

        if (input.trim().length >= 3) {
            if (type === this.class_search_enum) {
                this.get_insurance_class_list(input);
            } else {
                this.get_payer_list(input);
            }
        }

        if (type == this.class_search_enum) {
            error.insurance_class_id = true;
        } else {
            error.payer_key_id = true;
        }
        if (this._is_mount) {
            const { is_submitted, error } = this.state
            if (is_submitted) {
                if (type == this.class_search_enum) {
                    this.setState({ form: { ...this.state.form, insurance_class: null }, error: error });
                } else {
                    this.setState({ form: { ...this.state.form, insurance_payer: null }, error: error });
                }
            } else {
                if (type == this.class_search_enum) {
                    this.setState({ form: { ...this.state.form, insurance_class: null } });
                } else {
                    this.setState({ form: { ...this.state.form, insurance_payer: null } });
                }
            }
        }
    }

    //Auto search for class
    renderResult = (props) => {
        return (
            <div key={props.title} tabIndex={0} className="item_auto_search">
                <div key={props.title} className="fs_13">
                    <span>{props.label}</span>
                </div>
            </div>

        )
    }


    row_selection_format = (item, type) => {
        var formatted = null;
        if (type == this.class_search_enum) {

            formatted = {
                "title": `${item.id}`,
                "code": item.insurance_class_code,
                "name": item.insurance_class_description,
                "id": item.id,
                "label": (item.insurance_class_code) + " - " + (item.insurance_class_description),

            }
        } else if (type == this.payer_search_enum) {
            formatted = {
                "title": `${item.payer_key_id}`,
                "code": item.name,
                "description": item.description,
                "id": item.payer_key_id,
                "label": (item.name) + " - " + (item.description),

            }
        }

        return formatted;
    }
    // preapare the suggestion list with search result
    prepare_suggestion = (data, type) => {
        let formattedList = [];
        var data_length = data.data.length;
        if (data && data_length) {
            for (let i = 0; i < data_length; i++) {
                let item = data.data[i];
                if (type == this.class_search_enum) {
                    formattedList.push({
                        "title": `${item.id}`,
                        "code": item.insurance_class_code,
                        "name": item.insurance_class_description,
                        "id": item.id,
                        "label": item.insurance_class_code + (item.insurance_class_description ? `- ${item.insurance_class_description}` : '')
                    });
                } else if (type == this.payer_search_enum) {
                    formattedList.push({
                        "title": `${item.payer_key_id}`,
                        "code": item.name,
                        "description": item.description,
                        "id": item.payer_key_id,
                        "label": (item.name) + (item.description ? `- ${item.description}` : '')
                    });
                }
            }
        }
        return formattedList;
    }


    on_item_selection = (item, type) => {

        this.handle_selection_errors(item, type);
        this.on_advanced_search_grid_row_election(item, type);
    }

    handle_selection_errors = (item, type) => {
        const error = { ...this.state.error };
        if (type == this.payer_search_enum) {
            error.payer_key_id = this.is_bms_or_sysadmin ? (item ? (item.id || item.payer_key_id ? false : true) : true) : false;
        }
        if (type == this.class_search_enum) {
            error.insurance_class_id = false;
        }
        if (this._is_mount) {
            this.setState({
                error: error
            });
        }
    }

    on_advanced_grid_row_select = (item, type) => {
        this.handle_selection_errors(item, type);
        const prepared_result = this.row_selection_format(item, type);
        this.on_advanced_search_grid_row_election(prepared_result, type);
    }

    // search location by typing name or code
    on_advance_search = async (params, type) => {
        var search_data = null;
        var config_column = null;
        var grid_type = '';
        if (type == this.class_search_enum) {
            search_data = await advanced_search_class(params, this.token).then(res => res.data);
            config_column = constants.insurance.insurance_class_column_defs;
            grid_type = 'insurance_class_grid_data'
        } else if (type == this.payer_search_enum) {
            params.Description = typeof params.Description == 'undefined' ? '' : params.Description;
            params.Outbound_id = typeof params.Outbound_id == 'undefined' ? '' : params.Outbound_id;
            params.Inbound_id = typeof params.Inbound_id == 'undefined' ? '' : params.Inbound_id;
            params.name = typeof params.name == 'undefined' ? '' : params.name;
            search_data = await advanced_search_payer(params, this.token).then(res => res.data);
            config_column = constants.insurance.payerid_column_defs
            grid_type = 'insurance_payer_grid_data'
        }

        const search_result = search_data && search_data.data !== null ? search_data.data : [];
        const grid_data = { ...this.state.grid_conf, rows: search_result, column: config_column, messages: search_data.messages, paginationPageSize: 20 };
        if (this._is_mount) {
            this.setState({ [grid_type]: grid_data });
        }
    }

    // Will handle date selection from calendar  
    handleCalendarChange = (dt) => {
        let { form, error, is_submitted } = this.state;
        if (is_submitted) {
            error.icd_10_date = form.icd_code_type == '1' ? dt ? false : true : false
        }
        form.icd10_start_date = dt ? custom_date_format(dt, global_constants.date_format["mm/dd/yyyy"]) : dt;
        if (this._is_mount) {
            this.setState({
                form,
                error
            });
        }
    }

    hide_field_user_type = () => this.is_bms_or_sysadmin ? { display: 'block' } : { display: 'none' };
    show_html_content_toaster = msg => {
        return {
            component: () => (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: msg }} />
                </div>
            ),
            timeOut: global_constants.toastr_options.toastr_time_out,
            preventDuplicates: true
        };
    };


    on_change_icd_code = (evt, value) => {
        let { error, is_submitted, form } = this.state
        if (is_submitted) {
            error.icd_10_date = value == '1' ? form.icd10_start_date ? false : true : false
        }
        this.setState({ form: { ...this.state.form, icd_code_type: value }, error })
    }

    render_do_not_accept_assignment_checkbox() {
        return (
            this.props.argos_bill_1177_new_insurance_option_do_not_accept_assignment && (
                <Grid.Column mobile={16} tablet={8} computer={12}>
                    <Form.Field>
                        <label>&nbsp;</label>
                        <Checkbox label='Do Not Accept Assignment' name='do_not_accept_assignment' checked={this.state.form.do_not_accept_assignment}
                            onChange={(e) => this.setState({ form: { ...this.state.form, do_not_accept_assignment: !this.state.form.do_not_accept_assignment } })}
                            disabled={this.disable_fields}
                        />
                    </Form.Field>
                </Grid.Column>
            )
        );
    }

    render() {

        const form = { ...this.state.form };
        const { by_pass_prompt } = this.state
        return (
            <>
                {!by_pass_prompt && (this.is_bms_or_sysadmin || (!this.is_bms_or_sysadmin && !this.code_id)) && (<PromptNavigation
                    is_data_changed={JSON.stringify(this.initial_state_form) !== JSON.stringify(form)}
                    save={e => this.save_handle(e, true)}
                    go_next_location={this.go_next}
                    history={this.props.history}
                />)}
                <Dimmer active={this.state.is_loading}>
                    <Loader size='massive'>Loading</Loader>
                </Dimmer>
                <div className={'admin-wrapper  view-edit ' + this.page_metadata.id} style={insurance_css.insurance_id}>
                    <Grid className='headerGrid' style={insurance_css.headerGrid}>
                        <Grid.Column computer={16}>
                            <h3 className='ui header left aligned'>Insurances</h3>
                        </Grid.Column>
                    </Grid>
                    <div className='wrapper' style={insurance_css.wrapper}>
                        <Form autoComplete='off' name='view_edit_form' className='common-forms'>
                            <div id='admin-scrollable-area' style={insurance_css.scrollbar_area}>
                                <div className='patient-search-form patient_search_bottom_padding' id='search-criteria-container' style={{ paddingBottom: 0 }}>
                                    <Grid>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field className={this.state.error.insurance_name ? "requiredWithBgColor error" : ''} >
                                                <label>Insurance Name <span className={this.state.error.insurance_name ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                                <input
                                                    autoComplete="off"
                                                    name='insurance_name'
                                                    value={form.insurance_name || ''}
                                                    onChange={this.handleChange}
                                                    maxLength={50}
                                                    disabled={this.disable_fields}
                                                />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Insurance Code</label>
                                                <input
                                                    id='insurance_code_input'
                                                    autoComplete="off"
                                                    name='insurance_code'
                                                    value={form.insurance_code || ''}
                                                    onChange={this.handleChange}
                                                    disabled={true}
                                                />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field className={this.state.error.address1 ? "requiredWithBgColor error" : ''}>
                                                <label>Address 1 <span className={this.state.error.address1 ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                                <input
                                                    autoComplete="off"
                                                    name='address1'
                                                    value={form.address1}
                                                    onChange={this.handleChange}
                                                    id="case_name_txt" maxLength={50}
                                                    disabled={this.disable_fields}
                                                />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Address 2</label>
                                                <input
                                                    autoComplete="off"
                                                    onChange={this.handleChange}
                                                    name='address2'
                                                    value={form.address2 || ''}
                                                    id="case_name_txt" maxLength={50}
                                                    disabled={this.disable_fields}
                                                />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field className={this.state.error.city ? "requiredWithBgColor error" : ''}>
                                                <label>City <span className={this.state.error.city ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                                <input
                                                    autoComplete="off"
                                                    name='city'
                                                    value={form.city || ''}
                                                    onChange={this.handleChange}
                                                    id="case_name_txt" maxLength={50}
                                                    disabled={this.disable_fields}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field className={this.state.error.country_id ? "requiredWithBgColor error" : ''}>
                                                <label>Country ID <span className={this.state.error.country_id ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                                <SelectionComponent
                                                    name='country_id'
                                                    options={this.state.country_ids}
                                                    onChange={(value, event) => { this.handleDropDownChange(event, event.target) }}
                                                    defaultValue={this.state.form.country_id ? this.state.form.country_id : ''}
                                                    style='menu-options-inline'
                                                    disabled={this.disable_fields}
                                                />
                                            </Form.Field>
                                        </Grid.Column>

                                        {this.state.form.country_id == 1 && (<Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>State <span className={this.state.error.state ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                                <SelectionComponent
                                                    name='state'
                                                    placeHolder='Select'
                                                    options={this.state.states}
                                                    onChange={(value, event) => { this.handleDropDownChange(event, event.target) }}
                                                    defaultValue={this.state.form.state ? this.state.form.state : ''}
                                                    style='menu-options-inline'
                                                    isRequired={this.state.error.state ? true : false}
                                                    disabled={this.disable_fields}
                                                />
                                            </Form.Field>
                                        </Grid.Column>)}
                                        <Grid.Column style={this.state.form.country_id == 1 ? { display: 'block' } : { display: 'none' }} mobile={16} tablet={8} computer={4}>
                                            <Form.Field className={this.state.error.zip ? "requiredWithBgColor error" : ''}>
                                                <label>Zip Code <span className={this.state.error.zip ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                                <input
                                                    autoComplete="off"
                                                    name='zip'
                                                    value={form.zip ? zip_code_formation(form.zip) : ''}
                                                    onChange={this.handleChange}
                                                    maxLength={10}
                                                    className={this.state.form.zip.length > 0
                                                        && this.state.is_submitted && this.state.error.zip ? 'error' : ''}
                                                    disabled={this.disable_fields}
                                                />
                                                <div className="requiredText">{this.state.form.zip.length > 0
                                                    && this.state.is_submitted && this.state.error.zip
                                                    ? global_constants.constants.zip_compulsory : ''}</div>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column style={this.state.form.country_id != 1 ? { display: 'block' } : { display: 'none' }} mobile={16} tablet={8} computer={4}>
                                            <Form.Field className={this.state.error.province ? "requiredWithBgColor error" : ''}>
                                                <label>Province  <span className={this.state.error.province ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                                <input
                                                    autoComplete="off"
                                                    name='province'
                                                    value={form.province || ''}
                                                    onChange={this.handleChange}
                                                    id="case_name_txt" maxLength={20}
                                                    disabled={this.disable_fields}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column style={this.state.form.country_id != 1 ? { display: 'block' } : { display: 'none' }} mobile={16} tablet={8} computer={4}>
                                            <Form.Field className={this.state.error.postal_code ? "requiredWithBgColor error" : ''}>
                                                <label>Postal Code <span className={this.state.error.postal_code ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                                <input
                                                    autoComplete="off"
                                                    name='postal_code'
                                                    value={form.postal_code || ''}
                                                    onChange={this.handleChange}
                                                    maxLength={20}
                                                    className={this.state.form.postal_code.length > 0
                                                        && this.state.is_submitted && this.state.error.postal_code ? 'error' : ''}
                                                    disabled={this.disable_fields}
                                                />
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>Balance Type <span className={this.state.error.balance_type ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                                <SelectionComponent
                                                    name='balance_type'
                                                    placeHolder='Select'
                                                    options={this.state.balance_type}
                                                    onChange={(value, event) => { this.handleDropDownChange(event, event.target) }}
                                                    defaultValue={form.balance_type ? form.balance_type : ''}
                                                    style='menu-options-inline'
                                                    isRequired={this.state.error.balance_type ? true : false}
                                                    disabled={this.disable_fields}
                                                />

                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field className={this.state.error.insurance_class_id ? "requiredWithBgColor error advance-quick-search" : 'advance-quick-search'}>
                                                <label>Insurance Class <span className={this.state.error.insurance_class_id ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                                <AdvancedControl
                                                    disabled_element={this.disable_fields}
                                                    onGridRowSelection={(row) => this.on_advanced_grid_row_select(row, this.class_search_enum)}
                                                    gridConfig={this.state.insurance_class_grid_data}
                                                    controlId={'INSURANCE_CLASS'}
                                                    onSearch={param => this.on_advance_search(param, this.class_search_enum)}
                                                    search_type={'INSURANCE_CLASS'}
                                                    headerIdForGridTabNavigation={constants.insurance.insurance_classe_header_id}
                                                />
                                                <AutoSearchComponent
                                                    errorClass={''}
                                                    getInputValue={e => this.get_input_value(e, this.class_search_enum)}
                                                    default_value={this.state.selected_insurance_class}
                                                    errorMessage={'No Record Found !'}
                                                    prepareRenderList={this.renderResult}
                                                    getList={this.get_insurance_class_list}
                                                    prepareDataList={(data) => this.prepare_suggestion(data, this.class_search_enum)}
                                                    selectresult={val => this.on_item_selection(val, this.class_search_enum)}
                                                    is_disabled={this.disable_fields}
                                                    show_clear_search={true}
                                                    minimum_search_characters={2}
                                                    clear_search={() => this.clear_quick_search(this.class_search_enum)}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>Insurance Type ID <span className={this.state.error.insurance_type_id ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                                <SelectionComponent
                                                    name='insurance_type_id'
                                                    placeHolder='Select'
                                                    options={this.state.insurance_type_id}
                                                    onChange={(value, event) => { this.handleDropDownChange(event, event.target) }}
                                                    defaultValue={this.state.form.insurance_type_id ? this.state.form.insurance_type_id : ''}
                                                    style='menu-options-inline'
                                                    isRequired={this.state.error.insurance_type_id ? true : false}
                                                    disabled={this.disable_fields}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <br />
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field className={this.state.error.tax_identification_number ? "requiredWithBgColor error" : ''}>
                                                <label>Tax Identification Number</label>
                                                <input
                                                    id='tax_identification_number'
                                                    name='tax_identification_number'
                                                    type='text'
                                                    maxLength={9}
                                                    onChange={this.handleChange}
                                                    value={form.tax_identification_number || ''}
                                                    pattern='[0-9]*'
                                                    autoComplete="off"
                                                    disabled={this.disable_fields}
                                                />
                                                <div className="requiredText">{this.state.form.tax_identification_number.length > 0
                                                    && this.state.is_submitted && this.state.error.tax_identification_number
                                                    ? global_constants.constants.tin_compulsory : ''}</div>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field  className={this.state.error.taxonomy_code ? "requiredWithBgColor error" : ''}>
                                                <label>Taxonomy Code</label>
                                                <input
                                                    id='taxonomy_code'
                                                    name='taxonomy_code'
                                                    type='text'
                                                    maxLength={10}
                                                    onChange={this.handleChange}
                                                    value={form.taxonomy_code || ''}
                                                    autoComplete="off"
                                                    disabled={this.disable_fields}
                                                />
                                                <div className="requiredText">{this.state.form.taxonomy_code.length > 0
                                                    && this.state.is_submitted && this.state.error.taxonomy_code
                                                    ? global_constants.constants.txc_compulsory : ''}</div>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field className={this.state.error.type_of_bill ? "requiredWithBgColor error" : ''}>
                                                <label>Type of Bill</label>
                                                <input
                                                    id='type_of_bill'
                                                    name='type_of_bill'
                                                    type='text'
                                                    maxLength={3}
                                                    onChange={this.handleChange}
                                                    value={form.type_of_bill || ''}
                                                    autoComplete="off"
                                                    disabled={this.disable_fields}
                                                />
                                                <div className="requiredText">{this.state.form.type_of_bill.length > 0
                                                    && this.state.is_submitted && this.state.error.type_of_bill
                                                    ? global_constants.constants.tob_compulsory : ''}</div>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <br />
                                        </Grid.Column>
                                        {this.is_bms_or_sysadmin && (<Grid.Column style={this.hide_field_user_type()} mobile={16} tablet={8} computer={4}>
                                            <Form.Field className={this.state.error.payer_key_id ? "requiredWithBgColor error advance-quick-search" : 'advance-quick-search'}>
                                                <label>Payer Description <span className={this.state.error.payer_key_id ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                                <AdvancedControl
                                                    disabled_element={!this.is_bms_or_sysadmin || this.disable_fields}
                                                    onGridRowSelection={(row) => this.on_advanced_grid_row_select(row, this.payer_search_enum)}
                                                    gridConfig={this.state.insurance_payer_grid_data}
                                                    controlId={'PAYER_DESCRIPTION'}
                                                    onSearch={param => this.on_advance_search(param, this.payer_search_enum)}
                                                    search_type={'PAYER_DESCRIPTION'}
                                                    headerIdForGridTabNavigation={constants.insurance.payerid_header_id}
                                                />
                                                <AutoSearchComponent
                                                    is_disabled={!this.is_bms_or_sysadmin || this.disable_fields}
                                                    errorClass={''}
                                                    getInputValue={e => this.get_input_value(e, this.payer_search_enum)}
                                                    default_value={this.state.selected_payer}
                                                    errorMessage={'No Record Found !'}
                                                    prepareRenderList={this.renderResult}
                                                    getList={this.get_payer_list}
                                                    prepareDataList={(data) => this.prepare_suggestion(data, this.payer_search_enum)}
                                                    selectresult={item => this.on_item_selection(item, this.payer_search_enum)}
                                                    show_clear_search={true} clear_search={() => this.clear_quick_search(this.payer_search_enum)}
                                                />
                                            </Form.Field>
                                        </Grid.Column>)}
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field >
                                                <label>Contact Name</label>
                                                <input
                                                    autoComplete="off"
                                                    onChange={this.handleChange}
                                                    id="contact_name_txt"
                                                    name="contact_name"
                                                    value={form.contact_name || ''}
                                                    maxLength={70}
                                                    disabled={this.disable_fields}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>Contact Email</label>
                                                <input
                                                    autoComplete="off"
                                                    onChange={this.handleChange}
                                                    id="contact_name_email"
                                                    name="contact_email"
                                                    value={form.contact_email || ''}
                                                    maxLength={50}
                                                    className={this.state.form.contact_email.length > 0 &&
                                                        this.state.is_submitted &&
                                                        this.state.error.contact_email ? "red-error-thin form-control" : 'form-control'}
                                                    disabled={this.disable_fields}
                                                />
                                                <div className="requiredText">{this.state.form.contact_email.length > 0 &&
                                                    this.state.error.contact_email &&
                                                    this.state.is_submitted ? global_constants.constants.valid_email : ''}</div>
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field className={this.state.is_submitted && this.state.error.phone_customer_service ? "requiredWithBgColor error" : ''}>
                                                <label>Phone Customer Service <span className={this.state.is_submitted && this.state.error.phone_customer_service ? "req-alert" : "req-alert_normal"}>(required)</span></label>
                                                <MaskedInput
                                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    className={this.state.form.phone_customer_service.length > 0 &&
                                                        this.state.is_submitted &&
                                                        this.state.error.phone_customer_service ? "red-error-thin form-control" : 'form-control'}
                                                    placeholder="(___) ___-____"
                                                    guide={true}
                                                    id='phone_customer_service'
                                                    name='phone_customer_service'
                                                    autoComplete='off'
                                                    showMask={true}
                                                    onChange={this.handleChange}
                                                    value={form.phone_customer_service || ''}
                                                    disabled={this.disable_fields}
                                                    
                                                />
                                                <div className="requiredText">{this.state.form.phone_customer_service.length > 0
                                                    && this.state.is_submitted && this.state.error.phone_customer_service
                                                    ? global_constants.constants.ten_digits_compulsory : ''}</div>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>Phone Claim Status</label>
                                                <MaskedInput
                                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    className={this.state.form.phone_claim_status.length > 0 &&
                                                        this.state.is_submitted &&
                                                        this.state.error.phone_claim_status ? "red-error-thin form-control" : 'form-control'}
                                                    placeholder="(___) ___-____"
                                                    guide={true}
                                                    name="phone_claim_status"
                                                    id="phone_claim_status"
                                                    value={form.phone_claim_status || ''}
                                                    showMask={true}
                                                    autoComplete="off"
                                                    onChange={this.handleChange}
                                                    disabled={this.disable_fields}/>
                                                <div className="requiredText">{this.state.form.phone_claim_status.length > 0
                                                    && this.state.is_submitted && this.state.error.phone_claim_status
                                                    ? global_constants.constants.ten_digits_compulsory : ''}</div>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>Fax</label>
                                                <MaskedInput
                                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                    className={this.state.form.fax.length > 0 &&
                                                        this.state.is_submitted &&
                                                        this.state.error.fax ? "red-error-thin form-control" : 'form-control'}
                                                    placeholder="(___) ___-____"
                                                    guide={true}
                                                    name="fax"
                                                    id="fax-no"
                                                    autoComplete="off"
                                                    value={form.fax || ''}
                                                    showMask={true}
                                                    onChange={this.handleChange}
                                                    disabled={this.disable_fields}/>
                                                <div className="requiredText">{this.state.form.fax.length > 0
                                                    && this.state.is_submitted && this.state.error.fax
                                                    ? global_constants.constants.ten_digits_compulsory : ''}</div>
                                            </Form.Field>
                                        </Grid.Column>

                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>Weblink</label>
                                                <input
                                                    autoComplete="off"
                                                    name='weblink'
                                                    value={form.weblink || ''}
                                                    onChange={this.handleChange}
                                                    id="weblink" maxLength={250}
                                                    disabled={this.disable_fields}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        {this.is_bms_or_sysadmin && (<><Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>Weblink User ID</label>
                                                <input
                                                    autoComplete="off"
                                                    name='weblink_user_id'
                                                    onChange={this.handleChange}
                                                    id="weblink_user_id" maxLength={50}
                                                    value={form.weblink_user_id || ''}
                                                    disabled={this.disable_fields}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                            <Grid.Column mobile={16} tablet={8} computer={4}>
                                                <Form.Field>
                                                    <label>Weblink Password</label>
                                                    <input
                                                        autoComplete="off"
                                                        name='weblink_password'
                                                        value={form.weblink_password || ''}
                                                        onChange={this.handleChange}
                                                        id="weblink_password" maxLength={50}
                                                        disabled={this.disable_fields}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>

                                            <Grid.Column mobile={16} tablet={8} computer={4}>
                                                <Form.Field>
                                                    <label>DDE</label>
                                                    <input
                                                        autoComplete="off"
                                                        name='dde'
                                                        onChange={this.handleChange}
                                                        id="dde" maxLength={250}
                                                        value={form.dde || ''}
                                                        disabled={this.disable_fields}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column mobile={16} tablet={8} computer={4}>
                                                <Form.Field>
                                                    <label>DDE User ID</label>
                                                    <input
                                                        autoComplete="off"
                                                        name='dde_user_id'
                                                        onChange={this.handleChange}
                                                        id="dde_user_id" maxLength={50}
                                                        value={form.dde_user_id || ''}
                                                        disabled={this.disable_fields}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column mobile={16} tablet={8} computer={4}>
                                                <Form.Field>
                                                    <label>DDE Password</label>
                                                    <input
                                                        autoComplete="off"
                                                        name='dde_password'
                                                        onChange={this.handleChange}
                                                        id="dde_password" maxLength={50}
                                                        value={form.dde_password || ''}
                                                        disabled={this.disable_fields}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>

                                            <Grid.Column mobile={16} tablet={8} computer={4}>
                                                <Form.Field>
                                                    <label>Note</label>
                                                    <input
                                                        autoComplete="off"
                                                        name='note'
                                                        onChange={this.handleChange}
                                                        id="note" maxLength={250}
                                                        value={form.note || ''}
                                                        disabled={this.disable_fields}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column mobile={16} tablet={8} computer={4}>
                                                <Form.Field>
                                                    <label>Sec Routing</label>
                                                    <input
                                                        autoComplete="off"
                                                        name='sec_routing'
                                                        onChange={this.handleChange}
                                                        id="sec_routing"
                                                        maxLength={10}
                                                        value={form.sec_routing || ''}
                                                        disabled={this.disable_fields}
                                                    />
                                                </Form.Field>
                                            </Grid.Column></>)}
                                        {this.is_bms_or_sysadmin && (<><Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>ICD Codes for Billing</label>
                                                <SelectionComponent
                                                    name='icd10_code'
                                                    options={this.state.icd_code_types}
                                                    onChange={(value, event) => { this.on_change_icd_code(event, value) }}
                                                    defaultValue={this.state.form.icd_code_type ? this.state.form.icd_code_type : ''}
                                                    style='menu-options-inline'
                                                    disabled={this.disable_fields}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field className={this.state.error.icd_10_date ? "requiredWithBgColor" : ''}>
                                                <label>ICD-10 Start Date</label>
                                                    <DatePicker id="icd10_start_date"
                                                        // maxDate={moment().format('MM/DD/YYYY')}
                                                        date={this.state.form.icd10_start_date ? moment(this.state.form.icd10_start_date) : null}
                                                        date_update={this.handleCalendarChange}
                                                        is_required={this.state.error.icd_10_date}
                                                        is_disabled={this.disable_fields }
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>&nbsp;</label>
                                                <Checkbox label='Injury Date Required' name='is_active' checked={this.state.form.injury_date_required}
                                                        onChange={(e) => this.setState({ form: { ...this.state.form, injury_date_required: !this.state.form.injury_date_required } })}
                                                        disabled={this.disable_fields}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>&nbsp;</label>
                                                <Checkbox label='Diagnosis change requires new plan of care' name='diagnosis_change_required' checked={this.state.form.new_plan_required}
                                                        onChange={(e) => this.setState({ form: { ...this.state.form, new_plan_required: !this.state.form.new_plan_required } })}
                                                        disabled={this.disable_fields}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        </>)}

                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>&nbsp;</label>
                                                <Checkbox label='Authorization Required' name='authorization_required' checked={this.state.form.authorization_required}
                                                    onChange={(e) => this.setState({ form: { ...this.state.form, authorization_required: !this.state.form.authorization_required } })}
                                                    disabled={this.disable_fields}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={4}>
                                            <Form.Field>
                                                <label>&nbsp;</label>
                                                <Checkbox label='Apply Specialty Modifiers' name='apply_specialty_modifiers' checked={this.state.form.apply_specialty_modifiers}
                                                    onChange={(e) => this.setState({ form: { ...this.state.form, apply_specialty_modifiers: !this.state.form.apply_specialty_modifiers } })}
                                                    disabled={this.disable_fields}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} tablet={8} computer={this.props.argos_bill_1177_new_insurance_option_do_not_accept_assignment ? 4 : 12}>
                                            <Form.Field>
                                                <label>&nbsp;</label>
                                                <Checkbox label='Is Active' name='is_active' checked={this.state.form.is_active}
                                                    onChange={(e) => this.setState({ form: { ...this.state.form, is_active: !this.state.form.is_active } })}
                                                    disabled={this.disable_fields}
                                                />
                                            </Form.Field>
                                        </Grid.Column>
                                        {this.render_do_not_accept_assignment_checkbox()}
                                        <ContractsSelectionBoxComponent
                                            available_contracts={this.state.available_contracts ? this.state.available_contracts : []}
                                            selected_contracts={this.state.form.selected_contracts ? this.state.form.selected_contracts : []}
                                            add_all_contracts={this.add_all_contracts}
                                            add_selected_contracts={this.add_selected_contracts}
                                            remove_all_contracts={this.remove_all_contracts}
                                            remove_selected_contracts={this.remove_selected_contracts}
                                            select_contracts_to_add={this.select_contracts_to_add}
                                            select_contracts_to_remove={this.select_contracts_to_remove}
                                            select_contract_class='contract_class'
                                            disable_elements={this.disable_fields}
                                        />
                                    </Grid>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div className='sixteen wide computer sixteen wide mobile sixteen wide tablet column footer-area' id='applicationFooterSticky' style={insurance_css.footerArea}>
                        <Button id='cancel_button_id' type='button' onClick={(e) => { this.cancel_handler() }} basic>Cancel</Button>
                        {(this.is_bms_or_sysadmin || (!this.is_bms_or_sysadmin && !this.code_id)) && <Button type='submit' onKeyDown={set_focus_to_app_header} onClick={(e) => { this.save_handle(e, false) }} id='save_button_id' primary style={insurance_css.save_button} disabled={this.disable_fields}>Save</Button>}
                    </div>
                </div>
                <React.Fragment>
                    { this.render_insurance_profile_incomplete_modal() }
                </React.Fragment>
            </ >
        )
    }

}
// Css Style
const insurance_css = {
    insurance_id: {
        paddingBottom: 0
    },
    headerGrid: {
        marginTop: 0,
        marginBottom: 0,
        paddingRight: 15
    },
    wrapper: {
        marginBottom: 0
    },
    common_forms: {
        paddingTop: 0,
        height: '100%',
        paddingBottom: 0,
        display: 'flex',
        flexDirection: 'column'
    } as React.CSSProperties,

    patient_search_form: {
        paddingBottom: 0,
        flexBasis: 'auto',
        borderColor: '#cccccc',
        borderStyle: 'solid',
        borderWidth: '2px 1px 1px',
        margin: '0 0 15px 0px',
        background: '#fff',
        padding: '25px 15px'
    },
    footerArea: {
        paddingLeft: 0,
        paddingBottom: 0,
        marginBottom: 12,
        paddingRight: 25,
        paddingTop: 20
    },
    scrollbar_area: {
        height: '100%',
        // display: 'flex',
        // flexDirection: 'column',
        paddingRight: 15
    } as React.CSSProperties,
    save_button: {
        marginRight: 0
    }
};
const mapSTateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        shared_details: state.shared_details,
        argos_bill_1177_new_insurance_option_do_not_accept_assignment: get_launch_darkly_key_value(state.launch_darkly, render_do_not_accept_assignment.argosBill1177NewInsuranceOptionDoNotAcceptAssignment),
        leviathanBill3775InsuranceIncompleteConstantSaveValidation: get_launch_darkly_key_value(state.launch_darkly, leviathan_bill_3775_insurance_incomplete_constant_save_validation)
    };
}

export default connect(mapSTateToProps)(InsurancesAddEditComponent);