import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import { Icon, Modal, Popup, Transition } from 'semantic-ui-react';
import { header_ui_change, set_notes_modal_status } from '../../shared/action/shared_action';
import * as session_storage from '../../shared/session_storage_utility';
import * as local_storage from '../../shared/local_storage_utility';
import { get_patients_header, get_patient_header_case_info, update_collection_flag, update_patient_header_info } from '../action/patient_header_action';
import PatientInformationIconComponent from '../component/patient_header_information_icon_component';
import PrintPatientReceipt from '../component/print_patient_receipt';
import * as global_constant from './../../global_constants';
import * as Action from './../../shared/action/autosearch_action';
import AutoSearchComponent from './../../shared/component/auto_search_component';
import { caseFormat, format_dashes_number, format_date, get_age, nevigate_to_patient_view, phone_format, get_lauch_darkley_key_value, get_age_months, format_gender, open_new_tab } from './../../shared/utility';
import { handle_click_on_enter, handle_click_on_enter_with_focus, set_focus_on_element_with_id } from './../../shared/tab_navigation_utility';
import { set_active_patient, unsaved_form_data_for_prompt, custom_prompt } from '../../login/action/login_action';
import { patient_messages } from '../patient_constants';
import { UserTasks } from '../../user/component/user_tasks';
import { token_verify_post, sso_identity_application } from '../../sso/action/sso_action';
import * as batch_payment_constants from '../../payment/batch_payment/batch_payment_constants';
import { magnus_plat_5681_isolate_auth0, leviathan_bill_3690_company_type_webpt_new_patient_header } from '../../feature_flip_constant';

export class PatientheaderComponent extends React.Component<any, any> {
    _is_mounted = false;
    page_type = this.props.location.pathname == '/payments/batch_payments' ? batch_payment_constants.enum_page_type.batch_payments : batch_payment_constants.enum_page_type.batch_adjustment
    //_is_ff_task_icon = this.props.rsiB31397AddTaskIcon;
    constructor(props) {

        super(props);
        // Initialize state property here
        this.state = {
            shown: false,
            patientHeader: {},
            selectedCase: {},
            selected_case_id: '',
            patientId: this.props.patientId,
            showModal: false,
            quick_search_error_message: '',
            is_need_to_update: this.props.is_need_to_update,
            show_print_patient_receipt: false,
            is_force_update_info_icon: false,
            company_id: '',
            is_sso_user:false
        };
    }
    componentWillUnmount = () => {
        this._is_mounted = false;
        document.body.classList.remove('patient-subHeader', 'patient-header');
    }
    UNSAFE_componentWillMount = () => {
        document.body.classList.add('patient-header');
    }
    async componentDidMount() {
        this._is_mounted = true;
        this.get_patient_header_details(this.props.patientId);
        if (sessionStorage['sso_user_login']) {
            if (this.props.magnusPlat5681IsolateAuth0) {
                this.setState({
                    is_sso_user: true
                });
            }
            else {
                await token_verify_post().then((res) => {
                    if (this._is_mounted && res.data.content) {
                        this.setState({
                            is_sso_user: true
                        });
                    }
                })
            }
        } 
    }
    set_is_call_for_update_header = (flag) => {
        if (this._is_mounted) {
            this.setState({
                is_force_update_info_icon: flag
            });
        }
    }
    get_patient_header_details = (patientid, is_call_from_update_header = false) => {
        //return if patient id not find
        if (patientid == undefined || patientid == "") {
            return;
        }
        // Get token.
        var token = this.props.user_login_details.user_data.data.token_details.access_token;
        this.props.patient_header(patientid, token).then(async response => {
            if (response && response.payload && response.payload.data && response.payload.data.data) {
                await this.props.update_collection_flag(response.payload.data.data.in_collection);
                const header = response.payload.data.data || {};
                // API call to get patient case details
                //await this.props.get_case_info(+this.props.patientId, header.case_header[0].id, this.props.token);
                if (header && header.case_header && header.case_header.length > 0) {
                    var storage_case_id = session_storage.get('active_case');
                    var selectedCase = header.case_header[0];
                    const patient_case_list = header.case_header || [];
                    if (storage_case_id && Number(storage_case_id) > 0) {
                        var case_list = patient_case_list.filter(function (row, index) {
                            return (row.id == Number(storage_case_id));
                        });

                        if (case_list && case_list.length > 0) {
                            selectedCase = case_list[0];
                        }
                    }
                    session_storage.set('active_case', selectedCase.id.toString());

                    await this.props.get_case_info(patientid, selectedCase.id, token);
                    if (this._is_mounted) {
                        this.setState({
                            patientHeader: header,
                            selectedCase: selectedCase,
                            selected_case_id: selectedCase.id,
                            is_force_update_info_icon: is_call_from_update_header,
                            company_id: header.company_id
                        });
                    }
                } else {
                    await this.props.get_case_info(patientid, 0, token);
                    if (this._is_mounted) {
                        this.setState({
                            patientHeader: header,
                            selectedCase: {},
                            selected_case_id: '',
                            company_id:''
                        });
                    }
                }
            } else{
                this.header_error();
            }
        },
            error => {
                this.header_error();
            });
    };

    header_error = () => {
        let auth_data = local_storage.get("auth_data");
        if (auth_data && (auth_data.company_id == this.state.company_id)) {
            toastr.error('', patient_messages.patient_switch_error);
            this.props.history.replace('/patient_search');
        }
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousProps.patientId != this.props.patientId &&
            this.props.patientId != this.state.patientId) {
            if (this._is_mounted) {
                this.setState({
                    patientId: this.props.patientId
                }, () => {
                    this.get_patient_header_details(this.state.patientId);
                });
            }
        }
       
        if (previousProps.show !== this.props.show) {
            this.closeModal();
            this.props.onModalClose();
        }
        if ((previousProps.is_need_to_update != this.props.is_need_to_update) && this.props.is_need_to_update == true) {
            if (this._is_mounted) {
                this.setState({
                    patientId: this.props.patientId,
                    //is_need_to_update: false
                }, () => {
                    this.get_patient_header_details(this.state.patientId);
                });
            }
        }      
       
        if (previousProps.patient_header_reload != this.props.patient_header_reload && this.props.patient_header_reload) {
            this.get_patient_header_details(this.props.patientId, this.props.selcted_case_reload);
            this.props.update_patient_header_info(false, 0, false);
        } else if (previousProps.selcted_case_reload != this.props.selcted_case_reload && this.props.selcted_case_reload) {
            var storage_case_id = session_storage.get('active_case');
            var selectedCase = this.state.patientHeader.case_header[0];
            const patient_case_list = this.state.patientHeader.case_header || [];
            if (storage_case_id && Number(storage_case_id) > 0) {
                var case_list = patient_case_list.filter(function (row, index) {
                    return (row.id == Number(storage_case_id));
                });

                if (case_list && case_list.length > 0) {
                    selectedCase = case_list[0];
                }
            }
            if (this._is_mounted) {
                this.setState({
                    selectedCase: selectedCase,
                    selected_case_id: selectedCase.id
                });
            }
            this.props.get_case_info(this.state.patientId, selectedCase.id, this.props.user_login_details.user_data.data.token_details.access_token)
            this.props.update_patient_header_info(false, 0, false);
        }


        // if custom_prompt state is continue then invoke 
        if(this.props.check_custom_prompt_change != null && this.props.check_custom_prompt_change.stage &&    
            this.props.check_custom_prompt_change.value == global_constant.custom_prompt_values.PATIENT_CHANGE &&
            this.props.check_custom_prompt_change.stage == global_constant.custom_prompt_stages.CONTINUE) {
                                
            this.props.set_custom_prompt({}); 
            this.open_modal_for_patient_search();
        }
    }

    // toggle to show and hide patient case information under patient header
    private toggle = (close, e) => {
        if (this._is_mounted) {
            this.setState({
                shown: !this.state.shown
            });
        }
        document.body.classList.add('patient-subHeader');
        if (this.state.shown && !close) {
            var patient_id = session_storage.get('active_patient');
            var navigate_to = `/patient/${patient_id}`;
            //var navigate_to = `/coming_soon`;
            this.props.history.push(`${navigate_to}`);
        } else {
            this.props.header_ui_change(true);
            this.state.shown && document.body.classList.remove('patient-subHeader');
        }
    }

    private patient_name_carat_clicked = (close, e) => {
        if (this._is_mounted) {
            this.setState({
                shown: !this.state.shown
            });
        }

        if (this.state.shown && !close) {
            document.body.classList.add('patient-subHeader');
        } else {
            this.props.header_ui_change(true);
            this.state.shown && document.body.classList.remove('patient-subHeader');
        }

    }

    private emr_id_clicked = (alt_record_id2_display, e) => {

        //Window Open Patient EMR URL utilizes the V1 SSO authentication.
        const patient_emr_url = global_constant.billing_to_patient_emr_url + alt_record_id2_display;
        window.open(patient_emr_url, '_blank');

        // 11/13/2024 ::  USE THIS CODE WHEN SSO V2 AUTHENTICATION BECOMES AVAILABLE TO USE IN ALL ENVIRONMENTS.
        /* 
        const RESPONSE_STATUS_FORBIDDEN = 403;
        if (this.props.magnusPlat5681IsolateAuth0) {
            sso_identity_application().then(
                response => {
                    if (response.data) {
                        const userApplications = response.data;
                        const emrApplicationId = 1;
                        const emrApplication = userApplications.find((applicationItem) => {
                            return applicationItem.applicationId === emrApplicationId;
                        });
                        if (!emrApplication) {
                            return;
                        }
                        const patient_emr_url = global_constant.billing_to_patient_emr_url + alt_record_id2_display;
                        window.open(patient_emr_url, '_blank');
                    }
                    else {
                        open_new_tab(this.props.rsiB29536Dashboard);
                    }
                },
                error => {
                    if (error.response && error.response.status === RESPONSE_STATUS_FORBIDDEN) {
                        const toastrMessageOptions = {
                            timeOut: 5000,
                            onHideComplete: () => window.location.href = global_constant.sso_url.webpt_emr_authorize,
                        };
                        toastr.error("", "Hold tight! Your session has expired and you are being re-authenticated.", toastrMessageOptions);
                    }
                }
            );
        }
        else {
            var open_url = '';
            token_verify_post().then((res) => {
                if (res.data.content) {
                    var data = res.data.content.response;
                    var app_url = data.applications.filter((item) => {
                        return item.applicationId = 1;
                    });
                    open_url = `${app_url[0].homeUri}`
                    const form: HTMLFormElement = document.createElement("form");
                    form.method = "post";
                    form.action = open_url;
                    form.target = "_blank";
                    const input: HTMLInputElement = document.createElement("input");
                    input.type = "hidden";
                    input.name = "token";
                    input.value = data.sessionId;
                    form.appendChild(input);
                    document.body.appendChild(form);
                    form.submit();
                }
                else {
                    open_new_tab(this.props.rsiB29536Dashboard);
                }
            });
        }
        */
    }

    private billing_id_clicked = (close, e) => {
        var patient_id = session_storage.get('active_patient');
        var navigate_to = `/patient/${patient_id}`;
        this.props.history.push(`${navigate_to}`);
    }



    // handleChange function update the state selectCase with currently select case of a patient.   
    handleChange = (event) => {
        var token = this.props.user_login_details.user_data.data.token_details.access_token;

        var selectedCase_details = {};
        const patient_case_list = this.state.patientHeader.case_header || [];
        if (event.target.value && Number(event.target.value) > 0) {
            var case_list = patient_case_list.filter(function (row, index) {
                return (row.id == Number(event.target.value));
            });
            if (case_list && case_list.length > 0) {
                selectedCase_details = case_list[0];
            }
        }
        session_storage.set('active_case', event.target.value.toString());
        if (this._is_mounted) {
            this.setState({
                selectedCase: selectedCase_details,
                selected_case_id: Number(event.target.value)
            });
        }
        // API call to get patient case details

        this.props.get_case_info(+this.state.patientId, event.target.value, token)
    }

    onselection = (item) => {
        if (this._is_mounted) {
            this.setState({
                patientId: item.title,
                showModal: false
            }, () => {
                this.get_patient_header_details(this.state.patientId);
                this.props.set_active_patient(item.title);
                var navigate_to = `/patient/${item.title}`;
                session_storage.set('active_patient', item.title);
                if (nevigate_to_patient_view(this.props.location.pathname)) {
                    this.props.history.push(`${navigate_to}`);
                }
            });
        }
        this.set_focus_on_search_icon();
    }

    //close the modal on selection of patient through quick search
    closeModal = () => {
        if (this._is_mounted) {
            this.setState({ showModal: false });
            // set focus on parent
            let parentEle = document.getElementById("app_header_search_patient_icon");
            if(parentEle) {
                parentEle.focus();
            }
        }
    }

    set_focus_on_search_icon = () => {
        let parentEle = document.getElementById("app_header_search_patient_icon");
        if (parentEle) {
            parentEle.focus();
        }
    }

    is_emr_id_valid = (emr_id) :boolean => {
        let emr_id_valid = true;
        if (emr_id == undefined || emr_id == null || emr_id.trim().length == 0)
            emr_id_valid = false;
        
        return emr_id_valid;
    }

    is_webpt_company_type = () => {
        return (this.props.user_login_details.user_data.data.company_type_id == global_constant.a_company_type.Web_PT);
    }

    get_age_display = (dob) => {
        let age_years:number, age_months:number, display_age:string;
        age_years = get_age(dob);
        age_months = get_age_months(dob);
        display_age = (isNaN(age_years) || age_years == 0 ? '' : age_years + 'y ') + (isNaN(age_months) || age_months == 0 ? '' : age_months + 'm')
        return display_age;
    }

    get_gender_display = (gender: string) => {
        let display_gender: string='';

        if (gender == 'M')
        {
            display_gender = 'Male'
        } else if (gender == 'F')
        {
            display_gender = 'Female'
        };
        return display_gender;
    }

    get_primary_phone_display = () => {
        if (this.props.patient_details == null || this.props.patient_details == undefined) return '';
        if (this.props.patient_details.selected_patient == null || this.props.patient_details.selected_patient == undefined) return '';
        if (this.props.patient_details.selected_patient.data == null || this.props.patient_details.selected_patient.data == undefined) return '';
        if (this.props.patient_details.selected_patient.data.phone == null || this.props.patient_details.selected_patient.data.phone == undefined) return '';

        let display_phone: string;
        let phone_primary: number, phone_home: string, phone_cell: string, phone_work: string
        phone_primary = this.props.patient_details.selected_patient.data.phone.primary;
        phone_home = this.props.patient_details.selected_patient.data.phone.home;
        phone_cell = this.props.patient_details.selected_patient.data.phone.cell;
        phone_work = this.props.patient_details.selected_patient.data.phone.work;

        if (phone_primary == 2) {
            if (!(phone_cell == null)) {
                display_phone = phone_format(phone_cell) + ' (C)';
            }
        } else if (phone_primary == 3) {
            if (!(phone_work == null)) {
                display_phone = phone_format(phone_work) + ' (W)';
            }
        } else {
            if (!(phone_home == null)) {
                display_phone = phone_format(phone_home) + ' (H)';
            }
        }
        return display_phone;
    }

    //Prepare suggestion list to show in Quick Search result
    prepareSuggestion = (data) => {
        let formattedList = [];
        let isCompanyTypeWebPT = this.is_webpt_company_type();
        if (data && data.data.length > 0) {
            data.data.map((item) => {
                formattedList.push({
                    "label": (item.last_name == null ? '' : item.last_name + ' ') + (item.middle_initial == null ? '' : item.middle_initial + ' ') + (item.first_name == null ? '' : item.first_name),
                    "title":  item.id.toString(),
                    "title_display": isCompanyTypeWebPT && this.is_emr_id_valid(item.alt_record_id2) ? '(EMR ' + item.alt_record_id2 + ', Billing ' + item.id.toString() + ')' : '(Billing ' + item.id.toString() + ')',                   "email": item.email,
                    "dob": (item.date_of_birth == null ? '' : format_date(new Date(item.date_of_birth), false)).toString(),
                    "age": (item.date_of_birth == null ? '' : isCompanyTypeWebPT ? this.get_age_display(item.date_of_birth) : get_age(item.date_of_birth) + ' Yrs'),
                    "gender": item.gender == null ? '' : item.gender,
                    "home": (item.phone.home == null || item.phone.home == '' ? '' : isCompanyTypeWebPT ? ', ' + phone_format(item.phone.home) + ' (H)' : ' H: '  + format_dashes_number(item.phone.home)),
                    "work": (item.phone.work == null || item.phone.work == '' ? '' : isCompanyTypeWebPT ? ', ' + phone_format(item.phone.work) + ' (W)' : ', W: ' + format_dashes_number(item.phone.work)),
                    "cell": (item.phone.cell == null || item.phone.cell == '' ? '' : isCompanyTypeWebPT ? ', ' + phone_format(item.phone.cell) + ' (C)' : ', M: ' + format_dashes_number(item.phone.cell)),
                });

                return;
            });
        }
        else {
            if (this._is_mounted) {
                this.setState({ quick_search_error_message: 'No Patient Found !' })
            }
        }
        return formattedList;

    }
    renderResult = (props) => {

        if (this.is_webpt_company_type()) {
            return (
                <div key={props.title} tabIndex={0} className="item_auto_search">
                    <span className="bold"><span style={{ color: '#007298' }}>{props.label + ' '}</span> {props.title_display}</span>
                    <div className="fs_13">
                        <span>{props.dob + ' '}({props.age}){props.gender == '' ? '' : ', ' + props.gender}</span>
                        <span>{props.home}</span>
                        <span>{props.cell}</span>
                        <span>{props.work}</span>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div key={props.title} tabIndex={0} className="item_auto_search">
                    <span className="bold">{props.label}</span>
                    <div className="fs_13">
                        <span>#{props.title + ' '}</span>
                        <span>{props.dob + ' '}({props.age}),</span>
                        <span>{props.home}</span>
                        <span>{props.cell}</span>
                        <span>{props.work}</span>
                    </div>
                </div>
            )
        }
        
    }

    // getScrollAreaHeight = (shown) => {
    //     var scroolable_area = document.getElementById("scrollable-content-area");
    //     if (scroolable_area) {
    //         scroolable_area.removeAttribute('class');

    //         scroolable_area.setAttribute('class', shown ? 'auto-height-patient-full-details-scroll' : 'auto-height-patient-scroll');
    //     }
    // }

    getDataList = async (inputValue, companyid) => {
        var token = this.props.user_login_details.user_data.data.token_details.access_token;
        return await Action.get_searchList(token, companyid, inputValue.trim(), global_constant.constants.Quick_Search_Suggestion_List_Size, global_constant.end_points.patient_urls.quick_search);

    }
    print_patient_receipt_handle = () => {
        if (this._is_mounted) {
            this.setState({ show_print_patient_receipt: !this.state.show_print_patient_receipt });
        }
    }
    handle_keydown_of_search_icon = (event) => {

        if (event.keyCode == '13') {
            event.preventDefault();
            event.target.click();

            setTimeout(() => {
                set_focus_on_element_with_id("patient_header_quick_search_input");

            }, 10)
        }
    }

    handle_click_of_search_icon = () => {
        // applying custom prompt functionality for unsaved data.
        if(this.props.check_unsaved_form_data_for_prompt) {
            
            // Invoking the custom prompt for company change functionality
            this.props.set_custom_prompt({
                value: global_constant.custom_prompt_values.PATIENT_CHANGE,
                stage: global_constant.custom_prompt_stages.INVOKE
            });           
        } else {
            this.open_modal_for_patient_search();
        }
    }

    open_modal_for_patient_search = () => {
        if (this._is_mounted) {
          this.setState(
            {
              showModal: true
            },
            () => {
              let model_dimmer = document.getElementsByClassName("patient-modal");
              model_dimmer && model_dimmer[0] && model_dimmer[0].parentElement.classList.add("patient-modal-dimmer");
            }
          );
        }
        setTimeout(() => {
            set_focus_on_element_with_id("patient_header_quick_search_input");

        }, 10)
    }


    handle_enter_on_key_down_of_print_patient_reciept_icon = (event) => {
        if (event.keyCode == '13') {
            event.preventDefault();

            this.print_patient_receipt_handle()
        }
    }

    handle_on_open_modal_window = () => {
        setTimeout(() => {
            let closeIcons: NodeListOf<HTMLElement> = document.querySelectorAll('i.close.icon') as NodeListOf<HTMLElement>;
            let closeIcon = closeIcons[closeIcons.length - 1]
            closeIcon.tabIndex = 0;
            closeIcon.id = "app_header_search_modal_close_icon";
            let batch_pages = false

            // setting the focus for the first time.
            let auto_search_component = document.getElementById("patient_header_quick_search_input");
            if (this.page_type == batch_payment_constants.enum_page_type.batch_payments || this.page_type == batch_payment_constants.enum_page_type.batch_adjustment){
                batch_pages = true
            }
            if(auto_search_component) {
                auto_search_component.addEventListener('keydown', function (event) {
                    if (event.keyCode == 9) {
                       event.preventDefault();
                        closeIcon.focus();
                    }

                    if (event.keyCode == 27) {
                        event.preventDefault();
                        if (batch_pages && document.getElementById('payment_amount')) {
                            document.getElementById('payment_amount').focus();
                        }
                    }
               })
   
            }
            
            closeIcon.addEventListener("keydown", function (event) {
                if (event.shiftKey && event.keyCode == 9) {
                    event.preventDefault();
                    if(auto_search_component) {
                        auto_search_component.focus();
                    }
                }

                if (event.keyCode == 13) {
                    event.preventDefault();
                    var el = event.target as HTMLElement
                    el.click();
                }
            });
        }, 200)
    }
    
    handle_enter_on_key_down = (event) => {
        if (event.keyCode == '13') {
            event.preventDefault();
            this.props.set_notes_modal_status(true);
        }
    }

    render() {
        const { showModal } = this.state;
        if (Object.keys(this.state.patientHeader).length === 0) return null;
        // { this.state.shown ? this.getScrollAreaHeight(false) : this.getScrollAreaHeight(true) }
        const { name, id, date_of_birth, patient_notes } = this.state.patientHeader;
        const { insurance, group_number, policy_number } = ((this.props.patient_header_case && this.props.patient_header_case.data && this.props.patient_header_case.data.subscriber && this.props.patient_header_case.data.subscriber.length > 0 && this.props.patient_header_case.data.subscriber[0]) ? this.props.patient_header_case.data.subscriber[0] : { insurance: undefined, group_number: undefined, policy_number: undefined });
        const _is_ff_task_icon = this.props.rsiB31397AddTaskIcon;
        const alt_record_id2 = this.props.patient_details.selected_patient && this.props.patient_details.selected_patient.data && this.props.patient_details.selected_patient.data.alt_record_id2 ? this.props.patient_details.selected_patient.data.alt_record_id2 : '0';
        const alt_record_id2_display = this.props.patient_details.selected_patient && this.props.patient_details.selected_patient.data && this.props.patient_details.selected_patient.data.alt_record_id2 ? this.props.patient_details.selected_patient.data.alt_record_id2 : '';
        const patient_name = `${name.first_name ? name.first_name : ''} ${name.middle_initial ? name.middle_initial : ''} ${name.last_name ? name.last_name : ''}`;
        const patient_gender = this.props.patient_details.selected_patient && this.props.patient_details.selected_patient.data && this.props.patient_details.selected_patient.data.gender ? this.get_gender_display(this.props.patient_details.selected_patient.data.gender) : '';
        const show_company_type_webpt_new_app_header = this.props.leviathanBill3690CompanyTypeWebptNewPatientHeader;

        return (
          <div className='fixedhead'>
            <div className='patientstrip' id='applicationPatientHeaderSticky'>
              <div className=' margin-zero fixed-shadow' style={ { flexDirection: 'row' } }>
                <div className={`${!show_company_type_webpt_new_app_header ? 'patient-header-parent-default' : 'patient-header-parent-company-type-webpt'}`}>
                  <div className={'patient_header_name_bar'}>
                    <p className='name'>
                      {
                        <Modal
                          onClose={this.closeModal}
                          open={showModal}
                          className='patient-modal'
                          onOpen={this.handle_on_open_modal_window}
                          trigger={
                            <span>
                              <img
                                src={'/src/assets/images/search.png'}
                                id='app_header_search_patient_icon'
                                {...((this.page_type == batch_payment_constants.enum_page_type.batch_payments || this.page_type == batch_payment_constants.enum_page_type.batch_adjustment)? { } : { tabIndex: 0})}
                                onKeyDown={this.handle_keydown_of_search_icon}
                                aria-hidden='true'
                                onClick={this.handle_click_of_search_icon}
                              />{' '}
                            </span>
                          }
                          closeIcon
                          closeOnDimmerClick={true}
                        >
                          <Transition visible={true} animation='scale' duration={500}>
                            <Modal.Content>
                              <Modal.Description>
                                <AutoSearchComponent
                                  control_id='patient_header_quick_search_input'
                                  errorMessage={this.state.quick_search_error_message}
                                  getList={this.getDataList}
                                  prepareRenderList={this.renderResult}
                                  prepareDataList={this.prepareSuggestion}
                                  selectresult={this.onselection}
                                  is_disabled={false}
                                  show_clear_search={false}
                                />
                              </Modal.Description>
                            </Modal.Content>
                          </Transition>
                        </Modal>
                                    }
                                    {this.props.user_login_details.user_data.data.company_type_id == 5 && show_company_type_webpt_new_app_header &&
                                        (<span>
                                            <span className={'company-type-webpt-patient-header-carat'} onClick={this.patient_name_carat_clicked.bind(this, false)}>
                                                <i aria-hidden="true" className={`angle-color angle ${this.state.shown ? 'up' : 'down'} icon`}></i>
                                            </span>
                                            <span className={this.props.patient_collection_flag ? 'patient-header-collection-name-link-bold-company-type-webpt' : 'company-type-webpt-patient-bold-patient-header'}>
                                                {patient_name}
                                                <span id='patient_name_link'>
                                                    {this.is_emr_id_valid(alt_record_id2_display) ?
                                                        <span>
                                                        <span onClick={this.emr_id_clicked.bind(this, alt_record_id2_display)} className={!this.props.patient_collection_flag ? 'emr-link-blue-text' : ''}>{' (EMR ' + alt_record_id2_display}</span>
                                                            <span style={{ cursor: 'auto' }}>{', '}</span>
                                                        <span onClick={this.billing_id_clicked.bind(this, false)} className={!this.props.patient_collection_flag ? 'billing-link-blue-text' : ''}>{'Billing ' + id + ')'}</span>
                                                        </span>
                                                        :
                                                    <span onClick={this.billing_id_clicked.bind(this, false)} className={!this.props.patient_collection_flag ? 'billing-link-blue-text' : ''}>{' (Billing ' + id + ')'}</span>
                                                    }
                                                </span>
                                            </span>
                                        </span>)
                                    }
                                    {this.props.user_login_details.user_data.data.company_type_id == 5 && !show_company_type_webpt_new_app_header &&
                                        (<span
                                            id='patient_header_name_link'
                                            className={this.props.patient_collection_flag ? 'patient-header-collection-name-link-bold' : 'bold-patient-header'}
                                            onKeyDown={handle_click_on_enter}
                                            data-target='#demo'
                                            onClick={this.toggle.bind(this, false)}
                                            tabIndex={0}
                                        >
                                            <span className={!this.props.patient_collection_flag ? 'blue-text' : ''}>
                                                {patient_name}
                                            </span>
                                            {this.is_emr_id_valid(alt_record_id2_display) ? ' (EMR ' + alt_record_id2_display + ', Billing ' + id + ')' : ' (Billing ' + id + ')'}
                                        </span>
                                        )
                                    }
                                    {this.props.user_login_details.user_data.data.company_type_id != 5 &&
                                        (<span
                                            id='patient_header_name_link'
                                            className={this.props.patient_collection_flag ? 'patient-header-collection-name-link' : 'patient-header-name-link'}
                                            tabIndex={0}
                                            onKeyDown={handle_click_on_enter}
                                            data-target='#demo'
                                            onClick={this.toggle.bind(this, false)}
                                        >
                                            {patient_name}
                                            <span>{id ? ' ' + id : ''}</span>
                                        </span>
                                        )
                                    }
                                </p>
                                {this.props.user_login_details.user_data.data.company_type_id == 5 && show_company_type_webpt_new_app_header &&
                                    (
                                        <div className={'company-type-webpt-patient-header-patient-details'}>
                                            {date_of_birth ? format_date(new Date(date_of_birth)) : ''}
                                            {date_of_birth ? ' (' + (this.get_age_display(date_of_birth)) + '), ' : ''}
                                            {patient_gender == '' ? '' : patient_gender + ', '}
                                            {this.get_primary_phone_display()}
                                        </div>
                                    )
                                }
                                {this.props.user_login_details.user_data.data.company_type_id == 5 && !show_company_type_webpt_new_app_header && (
                                    <div>
                                        {date_of_birth ? format_date(new Date(date_of_birth)) : ''}
                                        {date_of_birth ? ' (' + (this.get_age_display(date_of_birth)) + '), ' : ''}
                                        {patient_gender == '' ? '' : patient_gender + ', '}
                                        {this.get_primary_phone_display()}
                                    </div>

                                )}
                                {this.props.user_login_details.user_data.data.company_type_id != 5 &&
                                    (
                                        <div>
                                            Date of Birth: {date_of_birth ? format_date(new Date(date_of_birth)) : ''} (
                                            {date_of_birth ? get_age(date_of_birth) : ''})
                                        </div>
                                    )
                                }
                  </div>
                  <div
                    className={'patient_header_icon_bar text-left flag-align pat-header-icon-icemoon'}
                            >
                                {_is_ff_task_icon && this.state.is_sso_user ?
                                    <Popup trigger={<span id="patient_task" className="popOver my-patient-tasks-icon"><UserTasks is_patient_task={true} patient_id={alt_record_id2} /></span>
                        }
                            content='View Patient Tasks'
                            on='hover'
                        />
                        : null}
                               
                    <Popup
                      trigger={
                        <span className='popOver'>
                                            <i
                            className={'icon-File_iconLarge'}
                            id='patient_header_all_notes_icon'
                            tabIndex={0}
                            onKeyDown={this.handle_enter_on_key_down}
                            onClick={() => this.props.set_notes_modal_status(true)}
                          />
                        </span>
                      }
                      content='View All Account Notes'
                      on='hover'
                         />
                            <Popup
                                trigger={<span className="popOver"><Icon tabIndex={0} id="print_patient_receipt_handle_icon" onKeyDown={this.handle_enter_on_key_down_of_print_patient_reciept_icon} aria-hidden="true" name='angle right' onClick={this.print_patient_receipt_handle} className='fa-money money bill alternate outline' /></span>}
                                content='Print Patient Receipts'
                                on='hover'
                            />

                    {<PrintPatientReceipt patient_name={patient_name} show={this.state.show_print_patient_receipt} />}
                    {
                      // Form Patient Information Icon
                          this.props.patient_header_case && this.props.patient_header_case.data ? (
                          <PatientInformationIconComponent
                          patient_details={this.state.patientHeader}
                          selected_case={this.props.patient_header_case.data}
                          set_state_is_force_update={this.set_is_call_for_update_header}
                          is_render_again={this.state.is_force_update_info_icon}
                        />
                      ) : (
                        ''
                      )
                    }

                    {this.props.patient_collection_flag && (
                      <React.Fragment>
                        <Popup
                          id='patient_info-strip'
                          trigger={
                              <span className='popOver'>
                                  <i
                                      tabIndex={0}                                      
                                      id='collection_flag_icon'                                    
                                      className='icon-flag'
                                      />
                                     
                            </span>
                          }
                        >
                          <Popup.Content style={{ padding: '5px' }} content={'This account is in collections'} />
                        </Popup>
                     <span id="in_collection_new_header" className='in-collection-text'>In Collections</span>
                      </React.Fragment>
                    )}
                 </div>
                        {this.props.user_login_details.user_data.data.company_type_id == 5 && show_company_type_webpt_new_app_header ? (
                            <React.Fragment>
                                <div style={{ position: 'absolute', right: '1rem' }}>
                                    <div className='patient_header_ins_bar-company-type-webpt'>
                                        Ins Class:{' '}
                                    {insurance && insurance.insurance_class && insurance.insurance_class.name
                                        ? insurance.insurance_class.name
                                        : 'Not Specified'}
                                    </div>
                                    <div className='patient_header_ins_bar-company-type-webpt'>
                                        Ins Name: {insurance && insurance.name ? insurance.name : 'Not Specified'}
                                    </div>
                                </div>
                            </React.Fragment>

                        ) : (
                                <React.Fragment>
                                    <div className='patient_header_ins_bar'>
                                        Ins Class:{' '}
                                        {insurance && insurance.insurance_class && insurance.insurance_class.name
                                            ? insurance.insurance_class.name
                                            : 'Not Specified'}
                                    </div>
                                    <div className='patient_header_ins_bar'>
                                        Ins Name: {insurance && insurance.name ? insurance.name : 'Not Specified'}
                                    </div>
                                </React.Fragment> 
                            )
                        }
                 </div>
              </div>
              {this.state.shown && (
                <div className='change-shadow' id='info-row-2'>
                  <div className='row'>
                    <div className='width_100'>
                      <div className='width_33'>
                        <div>
                          <div className='form-group margin-zero padding-zero'>
                            <label className=''>Case : </label>&nbsp;
                            {this.state.patientHeader.case_header && this.state.patientHeader.case_header.length > 1 && (
                              <select
                                className='form-control input-sm enable'
                                onChange={this.handleChange}
                                value={this.state.selected_case_id || ''}
                              >
                                {this.state.patientHeader.case_header.map((value, index) => (
                                  <option key={value.id} value={value.id}>
                                    {caseFormat(value)}
                                  </option>
                                ))}
                              </select>
                            )}
                            {this.state.patientHeader.case_header &&
                              this.state.patientHeader.case_header.length == 1 && (
                                <label className=''>{caseFormat(this.state.patientHeader.case_header[0])}</label>
                              )}
                          </div>
                        </div>
                        <div>
                          <p className='ellipsis'>
                            <Popup
                              id='patient_notes'
                              trigger={<span className='comment'>{patient_notes ? patient_notes : ''}</span>}
                            >
                              <Popup.Content>
                                <span className='commentNormal'>{patient_notes ? patient_notes : ''}</span>
                              </Popup.Content>
                            </Popup>
                          </p>
                        </div>
                      </div>
                      <div className='width_33'>
                        <div>
                          Status:{' '}
                          {this.state.patientHeader ? (this.state.patientHeader.is_active ? 'Active' : 'Inactive') : ''}
                          {this.props.patient_header_case.data && this.props.patient_header_case.data.status
                            ? this.props.patient_header_case.data.status
                            : ''}
                        </div>
                        <div>
                          Ins Address:{' '}
                          {insurance &&
                          insurance.address &&
                          insurance.address.address1 &&
                          insurance.address.address1 != null
                            ? insurance.address.address1 + ', '
                            : ''}
                          {insurance &&
                          insurance.address &&
                          insurance.address.address2 &&
                          insurance.address.address2 != null
                            ? insurance.address.address2 + ', '
                            : ''}{' '}
                          {insurance && insurance.address && insurance.address.city && insurance.address.city != null
                            ? insurance.address.city + ', '
                            : ''}{' '}
                          {insurance &&
                          insurance.address &&
                          insurance.address.state_id &&
                          insurance.address.state_id != null
                            ? insurance.address.state_id + ' '
                            : ''}{' '}
                          {insurance && insurance.address && insurance.address.zip && insurance.address.zip != null
                            ? insurance.address.zip
                            : ''}
                        </div>
                        <div>
                          <p className='commentPayer'>
                            {insurance && insurance.payer_alert ? insurance.payer_alert : ''}
                          </p>
                        </div>
                      </div>
                      <div className='width_33'>
                        <div>Group Number: {group_number ? group_number : ''}</div>
                        <div>Policy Number: {policy_number ? policy_number : ''}</div>
                        <div>Ins Phone: {insurance && insurance.phone ? phone_format(insurance.phone) : ''}</div>
                      </div>
                    </div>
                  </div>
                  <a className='collapsebtn collapsed' data-target='#demo'>
                    <Icon
                      tabIndex={0}
                      onKeyDown={(event) => {
                        handle_click_on_enter_with_focus(event, 'patient_header_name_link');
                      }}
                      aria-hidden='true'
                      name='close'
                      className='ic_cross pull-right'
                      onClick={this.toggle.bind(this, true)}
                    ></Icon>
                  </a>
                </div>
              )}
            </div>
          </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        get_case_info: get_patient_header_case_info,
        update_collection_flag: update_collection_flag,
        patient_header: get_patients_header,
        header_ui_change: header_ui_change,
        update_patient_header_info: update_patient_header_info,
        set_notes_modal_status: set_notes_modal_status,
        set_active_patient: set_active_patient,
        set_unsaved_form_data_for_prompt: unsaved_form_data_for_prompt,
        set_custom_prompt: custom_prompt
    }, dispatch)
}


const mapStateToProps = (state) => {
    return {

        patient_header_case: state.patient_details.patient_header_case,
        patient_details:state.patient_details,
        user_login_details: state.user_login_details,
        patient_collection_flag: state.patient_details.patient_collection_flag ? state.patient_details.patient_collection_flag : false,
        patient_phone_primary: state.patient_details.phone_primary,
        patient_header_reload: state.patient_details.patient_header_reload,
        selected_patient_header_case_id: state.patient_details.selected_patient_header_case_id,
        selcted_case_reload: state.patient_details.selcted_case_reload,
        check_unsaved_form_data_for_prompt: state.user_login_details.unsaved_form_data_for_prompt,
        check_custom_prompt_change: state.user_login_details.custom_prompt,
        rsiB31397AddTaskIcon: get_lauch_darkley_key_value(state.launch_darkly, "rsiB31297AddTasksIconToPatientHeader"),
        magnusPlat5681IsolateAuth0: get_lauch_darkley_key_value(state.launch_darkly, magnus_plat_5681_isolate_auth0),
        leviathanBill3690CompanyTypeWebptNewPatientHeader: get_lauch_darkley_key_value(state.launch_darkly, leviathan_bill_3690_company_type_webpt_new_patient_header)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PatientheaderComponent))