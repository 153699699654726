import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Icon, Popup } from 'semantic-ui-react';
import * as global_constant from '../../global_constants';
import * as service_utility from './../../shared/utility';
import { handle_click_on_enter } from './../../shared/tab_navigation_utility';
import * as local_storage from '../../shared/local_storage_utility';
import { phone_format, custom_date_format, get_lauch_darkley_key_value as get_launch_darkly_key_value } from './../../shared/utility';
import { OnlinePaymentSupportFlag } from '../../company/company_constants';

export class CompanyInformationIconComponent extends React.Component<any, any> {
    [x: string]: any;
    _is_mounted = false;
    constructor(props) {
        super(props);

        this.state = {
            icon_color: "white_icon company_info_icon",
        }

    }

    componentDidMount() {
        this._is_mounted = true;
    }

    componentDidUpdate(previousProps, previousState) {

    }

    componentWillUnmount() {
        this._is_mounted = false;
    }

    render() {
        var old_user_data = local_storage.get("auth_data");
        var accural_flag = '';
        var open_month = '';
        var Company_tax_id = '';
        var Company_contact = '';
        var Company_email = '';
        var Phone = '';
        var rcm_hub = '';
        var payment_url = '';
        var cpI_page_url = '';
        var sys_admin_user = 1;
        var bms_user = 2;
        var current_user_type = 0;
        if (old_user_data) {
            accural_flag = old_user_data.accrual_accounting || '';
            open_month = old_user_data.open_month ? custom_date_format(old_user_data.open_month, global_constant.date_format['mm/yyyy']) : "" || false;
            Company_tax_id = old_user_data.company_tax_id || '';
            Company_contact = old_user_data.company_contact || '';
            Company_email = old_user_data.company_email || '';
            Phone = old_user_data.phone ? phone_format(old_user_data.phone) : '';
            rcm_hub = old_user_data.hub_location || '';
            payment_url = old_user_data.online_Patient_payment_Url || '';
            cpI_page_url = old_user_data.cpI_page_url || '';
            current_user_type = old_user_data.user_type_id || 0;
        }
        return (
            <Popup id='company_info' on='click' size='mini' className="company_popup_position"
                trigger={ <span className="company_info-strip popOver"><Icon tabIndex={0} aria-hidden="true" name='info circle' className={this.state.icon_color} /> </span>}
                >
                <Popup.Content >
                    <ul className="company_info_container">
                        <li>
                            <Grid.Row columns={1} className="margin-zero">
                                <Grid.Column>
                                    <div>
                                        <span className="boldBlack">{global_constant.constants.company_information.current_open_month}</span>
                                    </div>
                                    <div>
                                        <span className="normalBlack">
                                            {accural_flag ? 'Accrual ' + open_month : open_month}
                                        </span>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1} className="margin-top-7">
                                <Grid.Column>
                                    <div>
                                        <span className="boldBlack">{global_constant.constants.company_information.company_tin}</span>
                                    </div>
                                    <div>
                                        <span className="normalBlack">{Company_tax_id}</span>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                            {
                                (rcm_hub != null && rcm_hub != '') ?
                                    <Grid.Row columns={1} className="margin-top-7">
                                        <Grid.Column>
                                            <div>
                                                <span className="boldBlack">{global_constant.constants.company_information.rcm_hub}</span>
                                            </div>
                                            <div>
                                                <span className="normalBlack">{rcm_hub}</span>
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                    : ""
                            }
                            <Grid.Row columns={1} className="margin-top-7">
                                <Grid.Column>
                                    <div>
                                        <span className="boldBlack">{global_constant.constants.company_information.contact_information}</span>
                                    </div>
                                    <div>
                                        <span className="normalBlack">{Company_contact}</span>
                                    </div>
                                    <div>
                                        <span className="normalBlack">{Company_email}</span>
                                    </div>
                                    <div>
                                        <span className="normalBlack">{Phone}</span>
                                    </div>
                                    {
                                        ((cpI_page_url != null && cpI_page_url != '') && (current_user_type == sys_admin_user || current_user_type == bms_user)) ?
                                            <div>
                                                <a href={cpI_page_url} target="_blank" className="boldBlack">{global_constant.constants.company_information.CPI}</a>
                                            </div>
                                            : ""
                                    }
                                </Grid.Column>
                            </Grid.Row>

                            {
                                ((payment_url != null && payment_url != '') && this.props.goliath_bill1938_online_payment_support) ?
                                    <Grid.Row columns={1} className="margin-top-7">
                                        <Grid.Column>
                                            <div>
                                                <span className="boldBlack"> {global_constant.constants.company_information.online_patient_payments}</span>
                                            </div>
                                            <div>
                                                <a href={payment_url} target="_blank" className="normalBlack">{payment_url}</a>
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                    : ""
                            }
                        </li>
                    </ul>
                </Popup.Content>
            </Popup>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({

    }, dispatch)
}


const mapStateToProps = (state) => {
    return {
        user_login_details: state.user_login_details,
        shared_details: state.shared_details,
        goliath_bill1938_online_payment_support: get_launch_darkly_key_value(state.launch_darkly, OnlinePaymentSupportFlag.goliath_bill1938_online_payment_support)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInformationIconComponent)